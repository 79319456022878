import React, { useEffect, useRef } from 'react';
import './ani.css';
import { CardBody, CardContainer, CardItem } from '../ui/3Dcard';
export default function Advertisement(props) {
    return(
        <div className="bg-white">
            <div className=" h-dvh mx-auto flex flex-col md:grid grid-cols-2 justify-center items-center md:p-10">
                <div className=' p-5'>
                    <img className=' object-cente object-fill h-96' alt='Digital Marketing' src='/images/DigitalMarketing/digitalMarketing.jpg'/>
                </div>
                <div>
                    <h2 className="text-5xl font-semibold  text-gray-800">
                    Enhance Your Customer Engagement with <span className=' text-sky-500 font-bold '>Personalized Marketing</span>
                    </h2>
                    <p className="text-lg text-gray-700">
                    In today's competitive market, generic marketing strategies no longer cut it. At Work Technologies, we specialize in personalized marketing solutions that connect with your audience on a deeper level.
                    </p>
                </div>
            </div>
            <div className="bg-gray-100 py-12 ">
                <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-semibold text-center text-gray-800 mb-8">Boost Your Business with Social Media <span className=' text-sky-500'>Ads</span></h2>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div  className="bg-white rounded-lg shadow-md p-6 animation_scroll_fadeIn hover:shadow-sky-400">
                            <h3 className="text-xl font-semibold text-gray-800 mb-4">Instagram Ads</h3>
                            <p className="text-gray-700">
                            Boost your brand's visibility with eye-catching Instagram ads. Engage with a vibrant audience and drive more traffic to your business. Start today and see the difference!
                            </p>
                        </div>
                        <div  className="bg-white rounded-lg shadow-md p-6 animation_scroll_fadeIn hover:shadow-sky-400">
                            <h3 className="text-xl font-semibold text-gray-800 mb-4">Facebook Ads</h3>
                            <p className="text-gray-700">
                            Reach your ideal customers with targeted Facebook ads. Increase your brand awareness and conversions with our expertly crafted campaigns. Get started now!
                            </p>
                        </div>
                        <div  className="bg-white rounded-lg shadow-md p-6 animation_scroll_fadeIn hover:shadow-sky-400">
                            <h3 className="text-xl font-semibold text-gray-800 mb-4">LinkedIn Ads</h3>
                            <p className="text-gray-700">
                            Connect with professionals and decision-makers through LinkedIn ads. Enhance your B2B marketing strategy and generate high-quality leads. Promote your business today!
                            </p>
                        </div>
                        <div  className="bg-white rounded-lg shadow-md p-6 animation_scroll_fadeIn hover:shadow-sky-400">
                            <h3 className="text-xl font-semibold text-gray-800 mb-4">Twitter Ads</h3>
                            <p className="text-gray-700">
                            Amplify your message with dynamic Twitter ads. Engage in real-time conversations and expand your reach with tailored advertising solutions. Join the conversation now!
                            </p>
                        </div>
                    </div>
                </div>
                </div>
            <div className=' grid grid-cols -1 md:grid-cols-2 md:p-10 justify-center items-center'>
                <div className=" rounded-md p-6">
                    <h3 className=" text-3xl md:text-6xl py-5 text-center font-semibold text-gray-800 mb-4">Why Choose Personalized Marketing<span className=' text-sky-500 font-bold '>?</span></h3>
                    <ul className=" list-inside text-gray-700 grid grid-cols-2 gap-2">
                    <li className="mb-2 rounded-md shadow-md bg-white p-7 hover:shadow-sky-200 transition-shadow duration-300 animation_scroll_fadeIn">Tailored Campaigns: Create marketing messages that resonate with each individual customer.</li>
                    <li className="mb-2 rounded-md shadow-md bg-white p-7 hover:shadow-sky-200 transition-shadow duration-300 animation_scroll_fadeIn">Increased Engagement: Boost customer interaction and loyalty with relevant content.</li>
                    <li className="mb-2 rounded-md shadow-md bg-white p-7 hover:shadow-sky-200 transition-shadow duration-300 animation_scroll_fadeIn">Higher Conversion Rates: Drive more sales with personalized offers and recommendations.</li>
                    <li className="mb-2 rounded-md shadow-md bg-white p-7 hover:shadow-sky-200 transition-shadow duration-300 animation_scroll_fadeIn">Data-Driven Insights: Utilize advanced analytics to understand and predict customer behavior.</li>
                    </ul>
                </div>
                
            <div className=' p-10'>
                <CardContainer className="inter-var">
                    <CardBody className="bg-gray-50 relative group/card  dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-blue-200 shadow-lg dark:border-white/[0.2] border-black/[0.1] w-auto sm:w-[30rem] h-auto rounded-xl p-6 border  ">
                        <CardItem translateZ="100" className="w-full mt-4">
                        <img className=' object-cente object-fill' alt='Digital Marketing' src='/images/DigitalMarketing/DigitalMarketingGirl.jpeg'/>
                        </CardItem>
                    </CardBody>
                    </CardContainer>
                    
            </div>
            </div>
            <div class="py-12">
                <div class=" mx-auto flex flex-col md:grid grid-cols-2 md:p-10">
                    <h2 class=" text-3xl md:text-6xl font-semibold text-gray-800 m-8 animation_scroll_fadeInText">
                        Experience the <span className=' text-sky-500 font-bold'>Power of Personalization</span>
                    </h2>
                    <div className=' p-5'>
                        <p class="text-lg text-gray-700 mb-12">
                        From email marketing to social media and beyond, our personalized marketing strategies leverage advanced data analysis and customer insights to ensure that your message resonates with each individual. By tailoring your marketing campaigns to the unique preferences and behaviors of your audience, we guarantee that your message hits the mark every time.
                        </p>
                        <button className=' text-xl border-4 rounded-lg p-4 bg-sky-200 border-sky-400 hover:border-slate-50 hover:bg-sky-300 active:bg-sky-400 active:border-sky-300 transition-colors duration-500'> <a href="tel:+919136075185" className=' text-black'>Contact Us</a></button>
                    </div>
                    {/* <div class="bg-white rounded-lg shadow-md p-6">
                        <h3 class="text-xl font-semibold text-gray-800 mb-4">Why Choose Us?</h3>
                        <p class="text-gray-700">
                        At Work Technologies, we specialize in crafting personalized marketing strategies that deliver measurable results. Our team of experts combines cutting-edge technology with creative innovation to develop customized campaigns that meet your unique business goals.
                        </p>
                        <p class="text-gray-700 mt-4">
                        Contact us today to learn more and watch your business grow!
                        </p>
                    </div> */}
                </div>
            </div>
        </div>
        
    )
}