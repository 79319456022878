import React from 'react';
import './components.css';
import { Link } from 'react-router-dom';
export default function OurServices(props) {
    return(
        <>
        <div className=' p-5 text-center md:p-14 leading-10'>
            <h2 className="text-5xl font-semibold text-center text-gray-800 mb-8">Our Services</h2>
            <div className=' grid grid-flow-row gap-5 p-5 md:p-10 md:grid-cols-4 '>
                <div className='p-2  border shadow-2xl shadow-gray-500 hover:shadow-sky-300 transition-all duration-700 rounded-md hover:border-sky-400 hover:scale-105  animation_scroll_fadeIn'>
                    <div className="relative">
                        <img className=' object-cente object-fill  aspect-square' src='/images/gif/appDevelopment.gif'/>
                    </div>
                    <h1 className=' text-2xl font-bold py-5'>Application Development</h1>
                    <p className=' text-justify leading-5'>
                    <strong>Maximizing the value of your applications Web Application Mobile Application.</strong>Worktechnologies maximizes application value through flexible portal architecture, empowering business growth with freedom for enhancements and seamless system integration.
                    </p>
                </div>
                
                <div className='p-2  border shadow-2xl shadow-gray-500 hover:shadow-sky-300 transition-all duration-700 rounded-md hover:border-sky-400 hover:scale-105  animation_scroll_fadeIn'>
                    <div className="relative">
                        <img className=' object-center  aspect-square' src='/images/gif/agriDrone.gif'/>
                    </div>
                    <h1 className=' text-2xl font-bold py-5'>Agri Drone</h1>
                    <p className='text-justify leading-5'>
                        <strong>Maximizing the value of your online presence with Website Development.</strong>Worktechnologies prioritizes maximizing your online platform's potential. We focus on flexibility, scalability, and seamless feature integration to enhance your digital presence and support business growth.
                    </p>
                </div>
                
                <div className='p-2  border shadow-2xl shadow-gray-500 hover:shadow-sky-300 transition-all duration-700 rounded-md hover:border-sky-400 hover:scale-105  animation_scroll_fadeIn'>
                    <img className=' object-center  aspect-square' src='/images/gif/vehicleLocation.gif'/>
                    <h1 className=' text-2xl font-bold py-5'>Vehicle Tracking</h1>
                    <p className=' text-justify leading-5'>
                        <strong>Accelerate Success, Mitigate Risk, Maximize ROI.</strong> A vehicle Tracking systems combines the use of automotive vehicle location in individual vehicle with software that collects these fleet data for a comprehensive picture of vehicle locations.
                    </p>
                </div>
                
                <div className='p-2  border shadow-2xl shadow-gray-500 hover:shadow-sky-300 transition-all duration-700 rounded-md hover:border-sky-400 hover:scale-105  animation_scroll_fadeIn'>
                    <img className=' object-center  aspect-square' src='/images/gif/itOutsoursing.gif'/>
                    <h1 className=' text-2xl font-bold py-5'>It Outsourcing</h1>
                    <p className=' text-justify leading-5'>
                        <strong>Predictable IT costs,best practice and customized service quality. </strong>Genuine passion and enthusiasm for what we do .coupled with our ability to respond and adapt swiftly to client's needs makes us the one stop recruitment shop
                    </p>
                </div>
            </div>
        </div>
        <div className='m-2 md:m-4 p-5 text-center md:p-14 bg-sky-600'>
            <h1 className=' text-2xl md:text-4xl font-bold text-white'>Put the power of personal attention to work for you</h1>
            <div className='m-4'>
                <button className=' bg-white p-3 text-xl font-bold text-cyan-600 rounded-sm mx-4'>Join Us</button>
                <button className=' bg-white p-3 text-xl font-bold text-cyan-600 rounded-sm mx-4'>
                    <Link to={'/hotJob'}>
                        Hot Job
                    </Link>
                </button>
            </div>
        </div>
        </>       
    )
}