import './App.css';
import Navbar from './Component/navbar';
import './prev.css';
import SwiperDefault from './Component/Swiper';
import { About } from './Component/About';
import OurServices from './Component/ourServices';
import OurAchievement from './Component/Ourachievements';
import Products from './Component/Products';
import Footer from './Component/Footer';
import PrivacyPolicy from './Component/prviacyPolicy';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Highlights from './Component/SEOSMO';
import ProductsPage from './Component/mainPages/Products';
import Hotjob from './Component/mainPages/HotJob';
import Advertisement from './Component/mainPages/advertisements';
import WebAppDevelopmentPage from './Component/mainPages/webDevelopment';

function App() {
  return (
    <div className='  relative'>
      <Router>
        <Routes>
          <Route path="/" element={
            <>
              <Navbar/>
              <SwiperDefault/>
              <About/>
              <OurServices/>
              <Highlights/>
              <Products/>
              <OurAchievement/>
              <Footer/>
            </>
          } />
          <Route path="/products" element={
          <>    
            <Navbar/>
            <ProductsPage />
          </>
          }/>
          <Route path="/hotJob" element={
          <>    
            <Navbar/>
            <Hotjob />
          </>
          }/>
          <Route path="/advertisements" element={
          <>    
            <Navbar/>
            <Advertisement/>
          </>
          }/>
          <Route path="/webappdevelopment" element={
          <>    
            <Navbar/>
            <WebAppDevelopmentPage/>
          </>
          }/>
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
