import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './styles.css';

// import required modules
import { Pagination } from 'swiper/modules';

export default function SwiperDefault() {
  return (
    <>
      <Swiper pagination={true}
      loop={true}
      spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      modules={[Pagination]} className="mySwiper">
        <SwiperSlide>
            <div>
                <img src="/images/BannerImages/banner_ds1.jpg" className=' h-96 w-96' alt="" />
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div>
                <img src="/images/BannerImages/banner_ds2.jpg"  className=' h-96 w-96' alt="" />
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div>
                <img src="/images/BannerImages/banner_ds3.jpg"  className=' h-96 w-96' alt="" />
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div>
                <img src="/images/BannerImages/banner_ds4.jpg"  className=' h-96 w-96' alt="" />
            </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
