import React from 'react';

export default function ProductsPage(props) {
    return(
        <div className=' p-5 text-center md:p-14'>
            <h2 className="text-5xl font-semibold text-center text-gray-800 mb-8">Products</h2>
                <div className=' grid gap-5 p-5 md:p-10 md:grid-cols-3 '>
                    <div className='flex flex-col border shadow-lg hover:scale-105 rounded-md transition-all duration-700 text-left p-5 animation_scroll_fadeIn hover:shadow-sky-200'>
                        <h3 className='text-xl'>MFS</h3>
                        <div className="relative my-2">
                            <img className=' object-cente object-fill  ' src='/images/Products/work1.jpg'/>
                        </div>
                        <h2 className=' text-md'>The MFSTAB Terminal is all-in-one, affordable, WiFi enabled terminal.</h2>
                    </div>
                    
                    <div className='flex flex-col border shadow-lg hover:scale-105 rounded-md transition-all duration-700 text-left p-5 animation_scroll_fadeIn hover:shadow-sky-200'>
                        <h3 className='text-xl'>MFS 100</h3>
                        <div className="relative my-2">
                            <img className=' object-center  ' src='/images/Products/work2.jpg'/>
                        </div>
                        <h2 className=' text-md'>MFS100 is high quality USB fingerprint sensor for fingerprint authentication for desktop.</h2>
                    </div>
                    
                    <div className='flex flex-col border shadow-lg hover:scale-105 rounded-md transition-all duration-700 text-left p-5 animation_scroll_fadeIn hover:shadow-sky-200'>
                        <h3 className='text-xl'>VTS vehicle tracking system</h3>
                        <div className="relative my-2">
                            <img className=' object-center  ' src='/images/Products/work3.jpg'/>
                        </div>
                        <h2 className=' text-md'>Vehicle Tracking System you can have peace of mind that your entire fleet is where you expect them to be.</h2>
                    </div>
                </div>
            </div>
    )
}