import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function Navbar(props) {
   const [navbar,setNavbar] = useState(false);
   const navigate = useNavigate();
    return(
        <nav className=' flex w-screen sticky top-0 z-50'>
         <div className='flex flex-col md:flex-row items-center  bg-slate-100 w-full'>
            <div className='flex md:p-0 p-2 justify-between w-full md:w-fit'>
               <div className='p-2' onClick={()=> navigate('/')}>
                  <a href="#"><img src="/images/workLogo.png" className='h-7 md:h-10'/></a>
               </div>
               <div className='block md:hidden px-5'>
                  <button onClick={()=> setNavbar(!navbar)} className=' text-blue-800 text-xl'>
                     <span class="material-symbols-outlined text-3xl">
                        {
                           navbar
                           ?
                           'menu_open'
                           :
                           'menu'
                        }
                     </span>
                  </button>
               </div>
            </div>
            
            <div className={`  md:flex ${navbar ? ' block' : 'hidden'} justify-between w-full px-2`}>
               <ul className= {`flex flex-row  ${navbar && 'flex-col'} justify-evenly`}>
                  <li className='group p-3 hover:bg-sky-500 transition-colors duration-300'>
                        <button className='flex items-center '><Link to={'/'}>
                           Home
                           </Link>
                        </button>
                  </li>
                  <li className='group p-3 hover:bg-sky-500 transition-colors duration-300'>
                        <button className='flex items-center '><Link to={'/products'}>
                           Products
                           </Link>
                        </button>
                  </li>
                  {/* <li className='group h-full hover:bg-sky-500 transition-colors duration-300'>
                     <button className='flex p-2 items-center '>
                        <Link to={'/products'}>
                           Products
                        </Link>  
                     <span class="material-symbols-outlined">
                     arrow_drop_down
                     </span> 
                     </button>
                  <ul className='z-10 hidden group-hover:block absolute bg-blue-50 shadow-sm p-2'>
                        <li className=' group'>
                           <a href="#">Biometric system</a>    
                           <ul className='hidden group-hover:block'>
                              <li className=' hover:bg-blue-100 my-1 p-1'><a href="#">Fingerprint Recognition</a></li>
                              <li className=' hover:bg-blue-100 my-1 p-1'><a href="#">Voice Recognition</a></li>
                              <li className=' hover:bg-blue-100 my-1 p-1'><a href="#">Iris Recognition</a></li>
                              <li className=' hover:bg-blue-100 my-1 p-1'><a href="#">Face Recognition</a></li>          
                              <li className=' hover:bg-blue-100 my-1 p-1'><a tabindex="-1" href="#">vehicle tracking </a></li>
                              <li className=' hover:bg-blue-100 my-1 p-1'><a tabindex="-1" href="#">Agri Drone </a></li>
                           </ul>
                        </li>
                     </ul> 
                  </li>  */}
                  <li className='group p-3 hover:bg-sky-500 transition-colors duration-300'>
                     <button className='flex items-center '>Downloads <span class="material-symbols-outlined">
                     arrow_drop_down
                     </span></button>
                     <ul className='z-10 hidden group-hover:block absolute bg-blue-50 shadow-sm p-2'>
                        <li className='  hover:bg-blue-100 my-1 p-1'><a href="#">Biometric system Information<span ><i ></i></span> </a></li>
                        <li className='  hover:bg-blue-100 my-1 p-1'><a href="#">Mobile Application<span > <i ></i></span></a></li>
                        <li className='  hover:bg-blue-100 my-1 p-1'><a href="#">How to use vehicle tracking system?<span > <i ></i></span></a></li>
                     </ul>
                  </li>
                  <li className=' group p-3 hover:bg-sky-500 transition-colors duration-300'>
                     <button className='flex items-center '>Support &amp; Services <span class="material-symbols-outlined">
                     arrow_drop_down
                     </span>  </button>
                     <ul className='z-10 hidden group-hover:block  absolute bg-blue-50 shadow-sm p-2'>
                        <li className='  hover:bg-blue-100 my-1 p-1'><Link to={'/webappdevelopment'}>Web Application</Link></li>
                        <li className='  hover:bg-blue-100 my-1 p-1'><Link to={'/webappdevelopment'}>Mobile Application</Link></li>
                        {/* <li className='  hover:bg-blue-100 my-1 p-1'><a href="#">SEO/SMO Services </a></li> */}
                        <li className='  hover:bg-blue-100 my-1 p-1'><Link to={'/advertisements'}>Ads Digital Marketing</Link></li>
                        <li className='  hover:bg-blue-100 my-1 p-1'><a href="#">Internet Marketing</a></li>
                        {/* <li className='  hover:bg-blue-100 my-1 p-1'><a href="#">Graphic Designing</a></li> */}
                        
                     </ul>
                  </li>
                  <li className=' p-3 hover:bg-sky-500 transition-colors duration-300'><a href="#">Partners</a></li>
                  <li className=' p-3 hover:bg-sky-500 transition-colors duration-300'><a href="#contact">Contact</a></li>
               </ul>
               <ul className={`flex  ${navbar ? 'flex flex-col':'flex items-center'} `}>
                  <li className=' group p-2 hover:bg-sky-500 transition-colors duration-300'>
                     <button className='flex items-center '> Sign Up <span class="material-symbols-outlined">
                     arrow_drop_down
                     </span></button>
                     <ul className='z-10 hidden group-hover:block  absolute bg-blue-50 shadow-sm p-2'>
                        <li className='  hover:bg-blue-100 my-1 p-1'><a href="#">As Employee</a></li>
                        <li className='  hover:bg-blue-100 my-1 p-1'><a href="#">As Employer</a></li>
                     </ul>
                  </li>
                  <li className='group p-2 hover:bg-sky-500 transition-colors duration-300'>
                     <button className='flex items-center '> Login<span class="material-symbols-outlined">
                     arrow_drop_down
                     </span></button>
                     <ul className='z-10 hidden group-hover:block  absolute bg-blue-50 shadow-sm p-2'>
                        <li className='  hover:bg-blue-100 my-1 p-1'><a href="#">As Employee</a></li>
                        <li className='  hover:bg-blue-100 my-1 p-1'><a href="#">As Employer</a></li>
                     </ul>
                  </li>
                  <li >
                     <input className='p-1 shadow-sm rounded focus:outline-none border hover:shadow-lg hover:shadow-sky-200 active:border-2 active:border-sky-600'  type="text" placeholder="Search" aria-label="Search"/>
                  </li>
               </ul>
            </div>
         </div>
      </nav>
    )
}