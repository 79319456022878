import React from 'react';

export default function OurAchievement(props) {
    return(
        <>
        <div className=' p-5 text-center md:p-14 leading-10'>
            <h1 className=' text-2xl md:text-3xl'>Our Achievement</h1>
            <div className=' grid grid-cols-3 gap-5 p-5 md:p-10 md:grid-cols-6 justify-center items-center'>
                <div className='flex flex-col hover:bg-cyan-400 hover:scale-105 rounded p-1 transition-all duration-700'>
                        <img className=' object-cente ' src='/images/achievements/rg1.jpg'/>
                </div>
                
                <div className='flex flex-col hover:bg-cyan-400 hover:scale-105 rounded p-1 transition-all duration-700'>
                        <img className=' object-center  ' src='/images/achievements/rg2.jpg'/>
                </div>
                
                <div className='flex flex-col hover:bg-cyan-400 hover:scale-105 rounded p-1 transition-all duration-700'>
                        <img className=' object-center  ' src='/images/achievements/rg3.jpg'/>
                </div>
                
                <div className='flex flex-col hover:bg-cyan-400 hover:scale-105 rounded p-1 transition-all duration-700'>
                        <img className=' object-center  ' src='/images/achievements/rg4.jpg'/>
                </div>

                <div className='flex flex-col hover:bg-cyan-400 hover:scale-105 rounded p-1 transition-all duration-700'>
                        <img className=' object-center  ' src='/images/achievements/rg5.jpg'/>
                </div>
                
                <div className='flex flex-col hover:bg-cyan-400 hover:scale-105 rounded p-1 transition-all duration-700'>
                        <img className=' object-center  ' src='/images/achievements/rg6.jpg'/>
                </div>
            </div>
        </div>
        </>
    )
}