import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Highlights(props) {
    const navigate = useNavigate();
    return(
        <div className=' flex flex-col items-center '>
            <h1 className=' text-4xl'>Highlights</h1>
            <div className=' grid grid-cols-1 md:grid-cols-2 gap-5 p-10'>
                <div className='p-2  hover:border-sky-600 rounded-md group animation_scroll_fadeIn'>
                    <div className="relative ">
                        <img className=' object-center  group-hover:scale-105 transition-all duration-1000 h-96' alt='Telehealth services' src='/images/highlight/telehealth.png'/>
                    </div>
                    <h1 className=' text-2xl font-bold py-5'>Telehealth Services</h1>
                    <p className='text-justify leading-5'>
                        <strong>Virtual telehealth services</strong> in India are being redefined with state-of-the-art devices, including digital stethoscopes and the Vision Platform by Visionflex, enabling seamless video consultations, diagnostic imaging, and digital stethoscope sound sharing from home.
                    </p>
                </div>
                <div onClick={()=> navigate('/advertisements')} className='p-2  hover:border-sky-600 group animation_scroll_fadeIn '>
                    <div className="relative ">
                        <img className=' object-center group-hover:scale-105 transition-all duration-1000 h-96' alt='Telehealth services' src='/images/highlight/advertisment.jpg'/>
                    </div>
                    <h1 className=' text-2xl font-bold py-5'>Advertisement Services</h1>
                    <p className='text-justify leading-5'>
                        <strong>Boost Your Business with Work Technologies!</strong> Expertly crafted, multi-channel advertising campaigns tailored to your goals. Engage your audience and drive results with our creative and data-driven solutions. Contact us today to elevate your brand!
                    </p>
                </div>
            </div>
        </div>
    )
}