import React, { useEffect, useRef, useState } from 'react';
import { CardBody, CardContainer, CardItem } from '../ui/3Dcard';

const WebAppDevelopmentPage = () => {
 

  return (
    <div className="container mx-auto px-4">

      <div className="bg-white">
            <div className=" h-dvh mx-auto flex flex-col md:grid grid-cols-2 justify-center items-center md:p-10 py-20">
                <section className=' relative flex justify-center items-center h-96'>
                    <dotlottie-player src="https://lottie.host/a087d055-6e3b-486b-aa75-9c119aa24a16/ri4EiI9i4B.json" background="transparent" speed="1"  className=' h-9 p-10' loop autoplay></dotlottie-player>
                    {/* <div className='z-0'>
                        <dotlottie-player src="https://lottie.host/0808900a-41ff-42cd-a221-486b849ac138/Q5aiYnMwcG.lottie" background="transparent" speed="1" className='' loop autoplay></dotlottie-player>
                    </div>
                    <div className='z-10 absolute text-9xl'>
                        &lt;/&gt;
                    </div> */}
                </section>
                <div>
                    <h2 className="text-5xl font-semibold  text-gray-800">
                    Empowering Your Online Presence, <span className=' text-sky-600'>Anywhere, Anytime.</span>
                    </h2>
                    <p className="text-lg text-gray-700 mt-10">
                    Crafting custom solutions for both web and mobile platforms, we optimize your digital presence. With innovative designs and expert development, we ensure seamless experiences across devices. Empower your business to thrive in the digital landscape with our tailored solutions. Elevate your brand's reach and impact with our comprehensive web and mobile offerings.
                    </p>
                </div>
            </div>
            <div className=" py-12 ">
                <div className="max-w-4xl mx-auto md:px-4 sm:px-6 lg:px-8">
                    <h2 className="text-5xl font-semibold text-center text-gray-800 mb-8">Our Services</h2>
                    <div className="flex flex-col md:grid  md:grid-cols-2 md:gap-32">
                        <CardContainer className="inter-var">
                            <CardBody className=" relative group/card  dark:hover:shadow-2xl w-auto sm:w-[30rem] h-auto rounded-xl">
                                <CardItem translateZ="100" className="w-full ">
                                    <div  className="bg-white rounded-lg shadow-md p-6 border hover:border-sky-400">
                                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Web Development</h3>
                                            <p className="list-item  ">Responsive Website Design</p>
                                            <p className="list-item ">Custom Web Apppcation Development</p>
                                            <p className="list-item ">E-commerce Solutions</p>
                                            <p className="list-item ">Content Management Systems (CMS)</p>
                                            <p className="list-item ">Progressive Web Apps (PWAs)</p>
                                    </div>
                                </CardItem>
                            </CardBody>
                        </CardContainer>
                        <CardContainer className="inter-var">
                            <CardBody className=" relative group/card  dark:hover:shadow-2xl w-auto sm:w-[30rem] h-auto rounded-xl ">
                                <CardItem translateZ="100" className="w-full ">
                                    <div  className="bg-white rounded-lg shadow-md p-6 border hover:border-sky-400">
                                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Mobile App Development</h3>
                                        <ul className="list-disc pl-6">
                                            <li className="list-item">iOS App Development</li>
                                            <li className="list-item">Android App Development</li>
                                            <li className="list-item">Cross-Platform App Development</li>
                                            <li className="list-item">Native and Hybrid Apps</li>
                                            <li className="list-item">App Maintenance and Support</li>
                                        </ul>
                                    </div>
                                </CardItem>
                            </CardBody>
                        </CardContainer>
                    </div>
                </div>
                </div>
                <div className="mb-12 md:p-10">
                    <h2 className="text-5xl font-bold mb-4 text-center">Our Process</h2>
                    <ol className="list-decimal pl-6">
                        <p className='animationListScroll p-5 my-5 shadow-md border-b-4 hover:border-sky-300 transition-colors duration-500'><strong>Discovery:</strong> We start by understanding your business goals, target audience, and project requirements through comprehensive discovery sessions.</p>
                        <p className='animationListScroll p-5 my-5 shadow-md border-b-4 hover:border-sky-300 transition-colors duration-500'><strong>Design:</strong> Our team of designers creates stunning visual designs and user interfaces that captivate your audience and enhance user experience.</p>
                        <p className='animationListScroll p-5 my-5 shadow-md border-b-4 hover:border-sky-300 transition-colors duration-500'><strong>Development:</strong> Using the latest technologies and best practices, our developers bring your vision to life with clean, efficient code and robust functionality.</p>
                        <p className='animationListScroll p-5 my-5 shadow-md border-b-4 hover:border-sky-300 transition-colors duration-500'><strong>Testing:</strong> We conduct rigorous testing to ensure your website or app is bug-free, responsive, and performs flawlessly across all devices and platforms.</p>
                        <p className='animationListScroll p-5 my-5 shadow-md border-b-4 hover:border-sky-300 transition-colors duration-500'><strong>Deployment:</strong> Once everything is tested and approved, we deploy your solution to production environments and ensure a smooth launch.</p>
                        <p className='animationListScroll p-5 my-5 shadow-md border-b-4 hover:border-sky-300 transition-colors duration-500'><strong>Support:</strong> Our commitment doesn't end after deployment. We provide ongoing support, maintenance, and updates to keep your website or app running smoothly and securely.</p>
                    </ol>
                </div>
            <div className=' grid grid-cols -1 md:grid-cols-2 md:p-10 justify-center items-center'>
                <div className=" rounded-md md:p-6">
                    <h3 className=" text-3xl md:text-6xl py-5 text-center font-semibold text-gray-800 mb-4">Why Choose Us<span className=' text-sky-500 font-bold '>?</span></h3>
                    <ul className=" list-inside text-gray-700 grid grid-cols-2 gap-2">
                    <li className="mb-2 rounded-md shadow-md bg-white p-7 hover:shadow-sky-200 transition-shadow duration-300 animation_scroll_fadeIn"><strong>Expertise:</strong> Our team consists of skilled professionals with years of experience in web and app development, ensuring high-quality solutions that exceed expectations.</li>
                    <li className="mb-2 rounded-md shadow-md bg-white p-7 hover:shadow-sky-200 transition-shadow duration-300 animation_scroll_fadeIn"><strong>Custom Solutions:</strong> We offer custom solutions tailored to your specific requirements, ensuring a solution that perfectly fits your needs.</li>
                    <li className="mb-2 rounded-md shadow-md bg-white p-7 hover:shadow-sky-200 transition-shadow duration-300 animation_scroll_fadeIn"><strong>Innovative Approach:</strong> We stay updated with the latest technologies and trends to deliver cutting-edge solutions.</li>
                    <li className="mb-2 rounded-md shadow-md bg-white p-7 hover:shadow-sky-200 transition-shadow duration-300 animation_scroll_fadeIn"><li><strong>Client-Centric:</strong> We prioritize client satisfaction above all else, maintaining clear communication and collaboration throughout the project.</li></li>
                    </ul>
                </div>
                
            <div className=' p-10'>
                <CardContainer className="inter-var">
                    <CardBody className="bg-gray-50 relative group/card  dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-sky-200 shadow-lg dark:border-white/[0.2] border-black/[0.1] w-auto sm:w-[30rem] h-auto rounded-xl p-6 border  ">
                        <CardItem translateZ="100" className="w-full mt-4">
                            <dotlottie-player src="https://lottie.host/36b7d781-1af5-4c42-9454-e6d6b1165bd1/UEFIMU2Kqr.json" background="transparent" speed="1" className="" loop autoplay></dotlottie-player>
                        </CardItem>
                    </CardBody>
                    </CardContainer>
                    
            </div>
            </div>
            <div class="py-12">
                <div class=" mx-auto flex flex-col md:grid grid-cols-2 md:p-10">
                    <h2 class=" text-4xl md:text-6xl font-semibold text-gray-800 md:m-8 animationScrollText">
                        Experience the <span className=' text-sky-500 font-bold '>Power of Online Business</span>
                    </h2>
                    <div>
                        <p class="text-lg text-gray-700 mb-12">
                        Experience the power of online business with our cutting-edge web and mobile solutions. We design and develop platforms that drive engagement, streamline operations, and enhance user experiences. Our team of experts delivers personalized strategies to help your business succeed in the digital world. Transform your vision into reality and elevate your online presence with us.
                        </p>
                        <button className=' text-xl border-4 rounded-lg p-4 bg-sky-200 border-sky-400 hover:border-slate-50 hover:bg-sky-300 active:bg-sky-400 active:border-sky-300 transition-colors duration-500'><a href="tel:+919136075185" className=' text-black'>Contact Us</a></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default WebAppDevelopmentPage;
