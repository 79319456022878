import React from 'react';
export function About() {
    return(
        <div className=' p-5 text-center md:p-14 leading-10'>
            <h1 className=' text-2xl md:text-3xl '>About Us</h1>
            <h3 className='text-2xl italic font-bold mt-10'>
                We use our insight,Knowledge and big thinking to create paths to smarter communication
            </h3>
            <p className='text-sm leading-7 md:text-xl my-5 text-justify '>
                We'll help you take your business to the next level with comprehensive business technology solutions. We provide cost effective, competitive, and efficient solutions for all your business technology needs. We understand that you need more than just an IT company to fix what breaks after it breaks. Your business needs managed IT services and long term, strategic IT consulting to ensure your systems last longer and operate efficiently. We take your specific needs and budget into account when we work with you. Our engineers will develop a highly customized solution, not cookie cutter, for each of our customers. All of our strategies are made to work with your specific company to help you do what you do best. Creating the future of digital communication service. Digital Agency, Digital Telehealth ,Agri Drone ,Biometric,switches router wireless storage optical networking Product Schneider Electric Mantra ,Essl.We have an expertise team of social media, project mapping, technology mapping and headhunting’s
            </p>
        </div>
    )
}

