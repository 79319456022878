import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer(props) {
    return(
        <main className=' p-10 bg-black text-white'>
            
            {/* 
            -----------------------------------------------------------------------------
            //*----------------------- Diffrent Sections --------------------------------
            -----------------------------------------------------------------------------
            */}
            <section className=' grid grid-flow-row md:grid-cols-3 gap-2'>
                <div>
                    <h1 className=' text-blue-500'>CONTACT US</h1>
                    <ul className=''>
                        <li className='my-2'>
                            USA <br />
                            # 1410 Broadway, Suite 1804 NYC, New York, NY 10018 <br />
                            <span >+1-315-282-5890</span>
                        </li>
                        <li className='my-2'>
                            INDIA <br />
                            # Plot No 174/1G . Floor Street No.12 Bahrampur GZB - 201009
                        </li>
                        <li className='my-2'>
                            info@worktechnologies.co.in <br />
                            +91-913-607-5185
                        </li>
                    </ul>
                </div>
                <div>
                    <h1 className=' text-blue-500'>About Us</h1>
                    <ul>
                        <li className='my-2'>
                            About
                        </li>
                        <li className='my-2'>
                            What does WT do?
                        </li>
                        <li className='my-2'>
                            Advertise with us
                        </li>
                    </ul>
                </div>
                <div>
                    <h1 className=' text-blue-500'>TALK TO US</h1>
                    <ul>
                        <li className='my-2'>
                            Articles and Inside
                        </li>
                        <li className='my-2'>
                            Customer Feedback
                        </li>
                        <li className='my-2'>
                            Talk to HR
                        </li>
                    </ul>
                </div> 
                <div>
                    <h1>MY ACCOUNT</h1>
                    <ul>
                        <li className='my-2'>
                            Employee
                        </li>
                        <li className='my-2'>
                            Employer
                        </li>
                        <li className='my-2'>
                            Sign in/Register
                        </li>
                    </ul>
                </div>
            </section>
            {/* 
            -----------------------------------------------------------------------------
            //*-----------------------Social Media Accounts -----------------------------
            -----------------------------------------------------------------------------
            */}
            <section className='flex flex-col justify-center items-center'>
                <h1 className=' text-center text-2xl font-bold'>Follow Us</h1>
                <div className='grid grid-cols-4 gap-2 p-5'>
                    <button className=' bg-white text-black p-4 px-5 rounded-full'><i class="fa-brands fa-linkedin-in"></i></button>
                    <button className=' bg-white text-black p-4 px-5 rounded-full'><i class="fa-brands fa-facebook-f"></i></button>
                    <button className=' bg-white text-black p-4 px-5 rounded-full'><i class="fa-brands fa-x-twitter"></i></button>
                    <button className=' bg-white text-black p-4 px-5 rounded-full'><i class="fa-brands fa-instagram"></i></button>
                </div>
            </section>

            <hr className=' line-blue w-full self-start'/>
            {/* 
            -----------------------------------------------------------------------------
            //*-----------------------Social Media Accounts -----------------------------
            -----------------------------------------------------------------------------
            */}
            <section>
                <p className=' text-center'>© 2024 Copyright : <span className=' text-blue-600'>work technologies </span><Link  className=' text-blue-600' to="/privacyPolicy">Privacy Policy</Link></p>
            </section>
        </main>
    )
}