import React from 'react';
import { Link } from 'react-router-dom';

export default function Products(props) {
    return(
        <>
            <div className=' p-5 text-center md:p-14'>
            <h2 className="text-5xl font-semibold text-center text-gray-800 mb-8">Products</h2>
                <div className=' grid gap-5 p-5 md:p-10 md:grid-cols-3 '>
                    <div className='flex flex-col border shadow-md hover:scale-105 rounded-md transition-all duration-700 text-left p-5 shadowclass animation_scroll_fadeIn'>
                        <h3 className='text-xl'>MFS</h3>
                        <div className="relative my-2">
                            <img className=' object-cente object-fill  ' src='/images/Products/work1.jpg'/>
                        </div>
                        <h2 className=' text-md'>The MFSTAB Terminal is all-in-one, affordable, WiFi enabled terminal.</h2>
                    </div>
                    
                    <div className='flex flex-col border shadow-md hover:scale-105 rounded-md transition-all duration-700 text-left p-5 shadowclass animation_scroll_fadeIn'>
                        <h3 className='text-xl'>MFS 100</h3>
                        <div className="relative my-2">
                            <img className=' object-center  ' src='/images/Products/work2.jpg'/>
                        </div>
                        <h2 className=' text-md'>MFS100 is high quality USB fingerprint sensor for fingerprint authentication for desktop.</h2>
                    </div>
                    
                    <div className='flex flex-col border shadow-md hover:scale-105 rounded-md transition-all duration-700 text-left p-5 shadowclass animation_scroll_fadeIn'>
                        <h3 className='text-xl'>VTS vehicle tracking system</h3>
                        <div className="relative my-2">
                            <img className=' object-center  ' src='/images/Products/work3.jpg'/>
                        </div>
                        <h2 className=' text-md'>Vehicle Tracking System you can have peace of mind that your entire fleet is where you expect them to be.</h2>
                    </div>
                </div>
                <span class="material-symbols-outlined animate-bounce text-sky-600 flex items-center m-auto flex-col">
                        arrow_downward
                </span>
                <button className=' border-2 text-xl font-bold border-cyan-400 bg-cyan-100 p-4 rounded-lg flex items-center m-auto flex-col animate-pulse'>
                    <Link to='/products'>
                        View All
                    </Link>
                </button>
            </div>
        </>
    )
}