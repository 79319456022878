import React from 'react';

export default function PrivacyPolicy(props) {
    return(
        <div className=" p-10">
        <div className="body">
    <div>
        <strong>
            <span classNameName="font-size: 26px;">
                <span className="title">
                    <bdt className="block-component"></bdt>
                    <bdt className="question">PRIVACY POLICY</bdt>
                    <bdt className="statement-end-if-in-editor"></bdt>
                </span>
            </span>
        </strong>
    </div>
    <div>
        <br/>
    </div>
    <div>
        <span classNameName="color: rgb(127, 127, 127);">
            <strong>
                <span classNameName="font-size: 15px;">
                    <span className="subtitle">
                        Last updated <bdt className="question">January 06, 2024</bdt>
                    </span>
                </span>
            </strong>
        </span>
    </div>
    <div>
        <br/>
    </div>
    <div>
        <br/>
    </div>
    <div>
        <br/>
    </div>
    <div classNameName="line-height: 1.5;">
        <span classNameName="color: rgb(127, 127, 127);">
            <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                <span className="body_text">
                    This privacy notice for 
                    <bdt className="question">
                        worktechnologies<bdt className="block-component"></bdt>
                    </bdt>
                    (<bdt className="block-component"></bdt>
                    "<strong>we</strong>
                    ," "<strong>us</strong>
                    ," or "<strong>our</strong>
                    "<bdt className="statement-end-if-in-editor"></bdt>
                </span>
                <span className="body_text">
                    ), describes how and why we might collect, store, use, and/or share (<bdt className="block-component"></bdt>
                    "<strong>process</strong>
                    "<bdt className="statement-end-if-in-editor"></bdt>
                    ) your information when you use our services (<bdt className="block-component"></bdt>
                    "<strong>Services</strong>
                    "<bdt className="statement-end-if-in-editor"></bdt>
                    ), such as when you:
                </span>
            </span>
        </span>
        <span classNameName="font-size: 15px;">
            <span classNameName="color: rgb(127, 127, 127);">
                <span className="body_text">
                    <span classNameName="color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <bdt className="block-component"></bdt>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </div>
    <ul>
        <li classNameName="line-height: 1.5;">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span className="body_text">
                        Visit our website<bdt className="block-component"></bdt>
                        at 
                        <span classNameName="color: rgb(0, 58, 250);">
                            <bdt className="question">
                                <a href="http://www.worktechnologies.co.in" target="_blank" className="link">http://www.worktechnologies.co.in</a>
                            </bdt>
                        </span>
                        <span classNameName="font-size: 15px;">
                            <span classNameName="color: rgb(89, 89, 89);">
                                <span className="body_text">
                                    <span classNameName="font-size: 15px;">
                                        <span classNameName="color: rgb(89, 89, 89);">
                                            <bdt className="statement-end-if-in-editor">, or any website of ours that links to this privacy notice</bdt>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </li>
    </ul>
    <div>
        <bdt className="block-component">
            <span classNameName="font-size: 15px;">
                <span classNameName="font-size: 15px;">
                    <span classNameName="color: rgb(127, 127, 127);">
                        <span className="body_text">
                            <span classNameName="color: rgb(89, 89, 89);">
                                <span className="body_text">
                                    <bdt className="block-component"></bdt>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </bdt>
    </div>
    <ul>
        <li classNameName="line-height: 1.5;">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span className="body_text">
                        Download and use
                        <bdt className="block-component">
                            <bdt className="block-component"></bdt>
                            our Facebook application<bdt className="block-component">�</bdt>
                            (<bdt className="question">worktechnologies)</bdt>
                            <bdt className="statement-end-if-in-editor">,</bdt>
                            <bdt className="statement-end-if-in-editor">or any other application of ours that links to this privacy notice</bdt>
                        </bdt>
                    </span>
                </span>
            </span>
        </li>
    </ul>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span classNameName="color: rgb(127, 127, 127);">
            <span className="body_text">
                <span classNameName="color: rgb(89, 89, 89);">
                    <span className="body_text">
                        <bdt className="block-component"></bdt>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span className="body_text">
                    Engage with us in other related ways, including any sales, marketing, or events
                    <span classNameName="font-size: 15px;">
                        <span classNameName="color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <span classNameName="font-size: 15px;">
                                    <span classNameName="color: rgb(89, 89, 89);">
                                        <bdt className="statement-end-if-in-editor"></bdt>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </li>
</ul>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span classNameName="color: rgb(127, 127, 127);">
            <span className="body_text">
                <strong>Questions or concerns?�</strong>
                Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.<bdt className="block-component"></bdt>
                If you still have any questions or concerns, please contact us at <bdt className="question">info@worktechnologies.co.in</bdt>
                .
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <strong>
        <span classNameName="font-size: 15px;">
            <span className="heading_1">SUMMARY OF KEY POINTS</span>
        </span>
    </strong>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <strong>
                <em>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our�</em>
            </strong>
        </span>
    </span>
    <a className="link" href="#toc">
        <span classNameName="color: rgb(0, 58, 250); font-size: 15px;">
            <span className="body_text">
                <strong>
                    <em>table of contents</em>
                </strong>
            </span>
        </span>
    </a>
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <strong>
                <em>�below to find the section you are looking for.</em>
            </strong>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <strong>What personal information do we process?</strong>
            When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about�
        </span>
    </span>
    <a className="link" href="#personalinfo">
        <span classNameName="color: rgb(0, 58, 250); font-size: 15px;">
            <span className="body_text">personal information you disclose to us</span>
        </span>
    </a>
    <span className="body_text">.</span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <strong>Do we process any sensitive personal information?</strong>
            <bdt className="block-component"></bdt>
            We do not process sensitive personal information.<bdt className="else-block"></bdt>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <strong>Do we receive any information from third parties?</strong>
            <bdt className="block-component"></bdt>
            We do not receive any information from third parties.<bdt className="else-block"></bdt>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <strong>How do we process your information?</strong>
            We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about�
        </span>
    </span>
    <a className="link" href="#infouse">
        <span classNameName="color: rgb(0, 58, 250); font-size: 15px;">
            <span className="body_text">how we process your information</span>
        </span>
    </a>
    <span className="body_text">.</span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <strong>
                In what situations and with which <bdt className="block-component"></bdt>
                parties do we share personal information?
            </strong>
            We may share information in specific situations and with specific <bdt className="block-component"></bdt>
            third parties. Learn more about�
        </span>
    </span>
    <a className="link" href="#whoshare">
        <span classNameName="color: rgb(0, 58, 250); font-size: 15px;">
            <span className="body_text">when and with whom we share your personal information</span>
        </span>
    </a>
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            .<bdt className="block-component"></bdt>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <strong>How do we keep your information safe?</strong>
            We have <bdt className="block-component"></bdt>
            organizational<bdt className="statement-end-if-in-editor"></bdt>
            and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other <bdt className="block-component"></bdt>
            unauthorized<bdt className="statement-end-if-in-editor"></bdt>
            third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about�
        </span>
    </span>
    <a className="link" href="#infosafe">
        <span classNameName="color: rgb(0, 58, 250); font-size: 15px;">
            <span className="body_text">how we keep your information safe</span>
        </span>
    </a>
    <span className="body_text">.</span>
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <bdt className="statement-end-if-in-editor"></bdt>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <strong>What are your rights?</strong>
            Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about�
        </span>
    </span>
    <a className="link" href="#privacyrights">
        <span classNameName="color: rgb(0, 58, 250); font-size: 15px;">
            <span className="body_text">your privacy rights</span>
        </span>
    </a>
    <span className="body_text">.</span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <strong>How do you exercise your rights?</strong>
            The easiest way to exercise your rights is by <bdt className="block-component">submitting a�</bdt>
        </span>
    </span>
    <a className="link" href="https://app.termly.io/notify/0e29da7e-29d5-4ed6-b483-d4e0dbd4d7a1" rel="noopener noreferrer" target="_blank">
        <span classNameName="color: rgb(0, 58, 250); font-size: 15px;">
            <span className="body_text">data subject access request</span>
        </span>
    </a>
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <bdt className="block-component"></bdt>
            , or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">Want to learn more about what we do with any information we collect?�</span>
    </span>
    <a className="link" href="#toc">
        <span classNameName="color: rgb(0, 58, 250); font-size: 15px;">
            <span className="body_text">Review the privacy notice in full</span>
        </span>
    </a>
    <span classNameName="font-size: 15px;">
        <span className="body_text">.</span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div id="toc" classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span classNameName="color: rgb(127, 127, 127);">
            <span classNameName="color: rgb(0, 0, 0);">
                <strong>
                    <span className="heading_1">TABLE OF CONTENTS</span>
                </strong>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <a className="link" href="#infocollect">
            <span classNameName="color: rgb(0, 58, 250);">1. WHAT INFORMATION DO WE COLLECT?</span>
        </a>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <a className="link" href="#infouse">
            <span classNameName="color: rgb(0, 58, 250);">
                2. HOW DO WE PROCESS YOUR INFORMATION?<bdt className="block-component"></bdt>
            </span>
        </a>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span classNameName="color: rgb(0, 58, 250);">
            <a className="link" href="#whoshare">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>
        </span>
        <span className="body_text">
            <bdt className="block-component"></bdt>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="color: rgb(0, 58, 250); font-size: 15px;">
        <a className="link" href="#3pwebsites">
            4. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?<bdt className="statement-end-if-in-editor"></bdt>
        </a>
        <span classNameName="color: rgb(127, 127, 127);">
            <span classNameName="color: rgb(89, 89, 89);">
                <span className="body_text">
                    <span classNameName="color: rgb(89, 89, 89);">
                        <bdt className="block-component"></bdt>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <a className="link" href="#cookies">
            <span classNameName="color: rgb(0, 58, 250);">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span>
        </a>
        <span classNameName="color: rgb(127, 127, 127);">
            <span classNameName="color: rgb(89, 89, 89);">
                <span className="body_text">
                    <span classNameName="color: rgb(89, 89, 89);">
                        <bdt className="statement-end-if-in-editor"></bdt>
                    </span>
                </span>
                <span className="body_text">
                    <span classNameName="color: rgb(89, 89, 89);">
                        <span classNameName="color: rgb(89, 89, 89);">
                            <span classNameName="color: rgb(89, 89, 89);">
                                <bdt className="block-component"></bdt>
                            </span>
                        </span>
                        <bdt className="block-component"></bdt>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <a className="link" href="#inforetain">
            <span classNameName="color: rgb(0, 58, 250);">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
        </a>
        <span classNameName="color: rgb(127, 127, 127);">
            <span classNameName="color: rgb(89, 89, 89);">
                <span className="body_text">
                    <span classNameName="color: rgb(89, 89, 89);">
                        <span classNameName="color: rgb(89, 89, 89);">
                            <bdt className="block-component"></bdt>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <a className="link" href="#infosafe">
            <span classNameName="color: rgb(0, 58, 250);">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
        </a>
        <span classNameName="color: rgb(127, 127, 127);">
            <span classNameName="color: rgb(89, 89, 89);">
                <span className="body_text">
                    <span classNameName="color: rgb(89, 89, 89);">
                        <bdt className="statement-end-if-in-editor"></bdt>
                        <bdt className="block-component"></bdt>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <a className="link" href="#infominors">
            <span classNameName="color: rgb(0, 58, 250);">8. DO WE COLLECT INFORMATION FROM MINORS?</span>
        </a>
        <span classNameName="color: rgb(127, 127, 127);">
            <span classNameName="color: rgb(89, 89, 89);">
                <span className="body_text">
                    <span classNameName="color: rgb(89, 89, 89);">
                        <bdt className="statement-end-if-in-editor"></bdt>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span classNameName="color: rgb(0, 58, 250);">
            <a className="link" href="#privacyrights">9. WHAT ARE YOUR PRIVACY RIGHTS?</a>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <a className="link" href="#DNT">
            <span classNameName="color: rgb(0, 58, 250);">
                10. CONTROLS FOR DO-NOT-TRACK FEATURES<bdt className="block-component"></bdt>
            </span>
        </a>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <a className="link" href="#uslaws">
            <span classNameName="color: rgb(0, 58, 250);">11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span>
        </a>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;"></span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="color: rgb(0, 58, 250); font-size: 15px;">
        <a className="link" href="#clausea">12. DATANOTSTOREDWITHUS</a>
        <bdt className="statement-end-if-in-editor"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="block-component"></bdt>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <a className="link" href="#policyupdates">
            <span classNameName="color: rgb(0, 58, 250);">13. DO WE MAKE UPDATES TO THIS NOTICE?</span>
        </a>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <a className="link" href="#contact">
        <span classNameName="color: rgb(0, 58, 250); font-size: 15px;">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
    </a>
</div>
<div classNameName="line-height: 1.5;">
    <a className="link" href="#request">
        <span classNameName="color: rgb(0, 58, 250);">15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span>
    </a>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div id="infocollect" classNameName="line-height: 1.5;">
    <span classNameName="color: rgb(0, 0, 0);">
        <span classNameName="color: rgb(0, 0, 0); font-size: 15px;">
            <span classNameName="font-size: 15px; color: rgb(0, 0, 0);">
                <span classNameName="font-size: 15px; color: rgb(0, 0, 0);">
                    <span id="control" classNameName="color: rgb(0, 0, 0);">
                        <strong>
                            <span className="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span>
                        </strong>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div id="personalinfo" classNameName="line-height: 1.5;">
    <span className="heading_2" classNameName="color: rgb(0, 0, 0);">
        <span classNameName="font-size: 15px;">
            <strong>Personal information you disclose to us</strong>
        </span>
    </span>
</div>
<div>
    <div>
        <br/>
    </div>
    <div classNameName="line-height: 1.5;">
        <span classNameName="color: rgb(127, 127, 127);">
            <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                <span className="body_text">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <strong>
                                    <em>In Short:</em>
                                </strong>
                            </span>
                        </span>
                    </span>
                </span>
                <span className="body_text">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <strong>
                                    <em>�</em>
                                </strong>
                                <em>We collect personal information that you provide to us.</em>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </div>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                We collect personal information that you voluntarily provide to us when you 
                <span classNameName="font-size: 15px;">
                    <bdt className="block-component"></bdt>
                </span>
            </span>
            <span className="body_text">express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</span>
        </span>
    </span>
    </div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px;">
                    <bdt className="block-component"></bdt>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <strong>Personal Information Provided by You.</strong>
                The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
                <span classNameName="font-size: 15px;">
                    <span className="body_text">
                        <bdt className="forloop-component"></bdt>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span className="body_text">
                    <span classNameName="font-size: 15px;">
                        <span className="body_text">
                            <bdt className="question">names</bdt>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </li>
</ul>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px;">
                    <span className="body_text">
                        <bdt className="forloop-component"></bdt>
                    </span>
                    <span className="body_text">
                        <bdt className="statement-end-if-in-editor"></bdt>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div id="sensitiveinfo" classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <strong>Sensitive Information.</strong>
            <bdt className="block-component"></bdt>
            We do not process sensitive information.
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <bdt className="else-block"></bdt>
        </span>
    </span>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px;">
                    <span className="body_text">
                        <bdt className="block-component">
                            <bdt className="block-component"></bdt>
                        </bdt>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <bdt className="block-component"></bdt></span>
                    <span className="body_text">
                        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                            <span className="body_text">
                                <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                    <span className="body_text">
                                        <bdt className="statement-end-if-in-editor">
                                            <bdt className="block-component"></bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
        </span>
    </span>
<span classNameName="font-size: 15px;">
    <span className="body_text">
        <bdt className="block-component"></bdt>
    </span>
</span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <strong>Information collected when you use our Facebook application(s).</strong>
            We by default access your�
        </span>
        <span className="body_text">Facebook basic account information, including your name, email, gender, birthday, current city, and profile picture URL, as well as other information that you choose to make public. We may also request access to other permissions related to your account, such as friends, check-ins, and likes, and you may choose to grant or deny us access to each individual permission. For more information regarding Facebook permissions, refer to the�</span>
    </span>
    <a className="link" href="https://developers.facebook.com/docs/facebook-login/permissions" rel="noopener noreferrer" target="_blank">
        <span classNameName="color: rgb(0, 58, 250); font-size: 15px;">
            <span className="body_text">Facebook Permissions Reference</span>
        </span>
    </a>
    <span classNameName="font-size: 15px;">
        <span className="body_text">�page.</span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <bdt className="statement-end-if-in-editor"></bdt>
        </span>
    </span>
</div>
<div id="infouse" classNameName="line-height: 1.5;">
    <span classNameName="color: rgb(127, 127, 127);">
        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span id="control" classNameName="color: rgb(0, 0, 0);">
                        <strong>
                            <span className="heading_1">2. HOW DO WE PROCESS YOUR INFORMATION?</span>
                        </strong>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div>
    <div classNameName="line-height: 1.5;">
        <br/>
    </div>
    <div classNameName="line-height: 1.5;">
        <span classNameName="color: rgb(127, 127, 127);">
            <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                <span className="body_text">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <strong>
                                    <em>In Short:�</em>
                                </strong>
                                <em>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </div>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong>
                <bdt className="block-component"></bdt>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <bdt className="block-component"></bdt>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <bdt className="block-component"></bdt>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <bdt className="block-component"></bdt>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <bdt className="block-component"></bdt>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;"></span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;"></span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;"></span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<p classNameName="font-size: 15px; line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;"></span>
    </bdt>
</p>
<p classNameName="font-size: 15px; line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;"></span>
    </bdt>
</p>
<p classNameName="font-size: 15px; line-height: 1.5;">
    <bdt className="block-component"></bdt>
</p>
<p classNameName="font-size: 15px; line-height: 1.5;">
    <bdt className="block-component"></bdt>
</p>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;"></span>
            <span className="body_text"></span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;"></span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;"></span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <bdt className="block-component">
            <span className="body_text"></span>
        </bdt>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;"></span>
            <span className="body_text"></span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span className="body_text">
            <span classNameName="font-size: 15px;">
                <strong>To evaluate and improve our Services, products, marketing, and your experience.</strong>
                We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.
            </span>
        </span>
        <bdt className="statement-end-if-in-editor">
            <span classNameName="font-size: 15px;">
                <span className="body_text"></span>
            </span>
        </bdt>
    </li>
</ul>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>        
        </span>
    </bdt>
</div>
<bdt className="block-component">
    <span classNameName="font-size: 15px;">
        <span className="body_text"></span>
    </span>
</bdt>
<bdt className="block-component">
    <span classNameName="font-size: 15px;">
        <span className="body_text"></span>
    </span>
</bdt>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div id="whoshare" classNameName="line-height: 1.5;">
    <span classNameName="color: rgb(127, 127, 127);">
        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span id="control" classNameName="color: rgb(0, 0, 0);">
                        <strong>
                            <span className="heading_1">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span>
                        </strong>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <strong>
                    <em>In Short:</em>
                </strong>
                <em>
                    �We may share information in specific situations described in this section and/or with the following <bdt className="block-component"></bdt>
                    third parties.
                </em>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <bdt className="block-component"></bdt>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            We <bdt className="block-component"></bdt>
            may need to share your personal information in the following situations:
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px;">
            <span className="body_text">
                <strong>Business Transfers.</strong>
                We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
            </span>
        </span>
    </li>
</ul>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <bdt className="block-component"></bdt>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <bdt className="block-component">
            <span className="body_text"></span>
        </bdt>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
    <span className="body_text">
        <span classNameName="font-size: 15px;">
            <bdt className="block-component"></bdt>
        </span>
    </span>
    <bdt className="statement-end-if-in-editor">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <strong>
        <span id="3pwebsites" classNameName="font-size: 15px;">
            <span className="heading_1">4. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</span>
        </span>
    </strong>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <strong>
                <em>In Short:</em>
            </strong>
            <em>�We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but are not affiliated with, our Services.</em>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            The Services<bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
            <bdt className="statement-end-if-in-editor"></bdt>
            may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or applications. The inclusion of a link towards a third-party website, service, or application does not imply an endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.
        </span>
    </span>
    <bdt className="statement-end-if-in-editor">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
    <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
        <span classNameName="font-size: 15px;">
            <span classNameName="color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px;">
                    <span classNameName="color: rgb(89, 89, 89);">
                        <bdt className="block-component">
                            <span className="heading_1"></span>
                        </bdt>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div id="cookies" classNameName="line-height: 1.5;">
    <span classNameName="color: rgb(127, 127, 127);">
        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span id="control" classNameName="color: rgb(0, 0, 0);">
                        <strong>
                            <span className="heading_1">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span>
                        </strong>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <strong>
                    <em>In Short:</em>
                </strong>
                <em>�We may use cookies and other tracking technologies to collect and store your information.</em>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice
                <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                    <span className="body_text">
                        <bdt className="block-component"></bdt>
                    </span>
                </span>
                : 
                <span classNameName="color: rgb(0, 58, 250); font-size: 15px;">
                    <span className="body_text">
                        <bdt className="question">
                            <a href="http://www.worktechnologies.co.in/cookies" target="_blank" className="link">http://www.worktechnologies.co.in/cookies</a>
                        </bdt>
                    </span>
                </span>
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <bdt className="block-component"></bdt>
                    .
                </span>
                <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                    <span classNameName="font-size: 15px;">
                        <span classNameName="color: rgb(89, 89, 89);">
                            <span classNameName="font-size: 15px;">
                                <span classNameName="color: rgb(89, 89, 89);">
                                    <span className="body_text">
                                        <bdt className="statement-end-if-in-editor"></bdt>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
        <span className="body_text">
            <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                    <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                        <span classNameName="font-size: 15px;">
                            <span classNameName="color: rgb(89, 89, 89);">
                                <span classNameName="font-size: 15px;">
                                    <span classNameName="color: rgb(89, 89, 89);">
                                        <span className="body_text">
                                            <bdt className="block-component"></bdt>
                                        </span>
                                        <bdt className="block-component">
                                            <span className="body_text">
                                                <bdt className="block-component"></bdt>
                                            </span>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div id="inforetain" classNameName="line-height: 1.5;">
    <span classNameName="color: rgb(127, 127, 127);">
        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span id="control" classNameName="color: rgb(0, 0, 0);">
                        <strong>
                            <span className="heading_1">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
                        </strong>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <strong>
                    <em>In Short:�</em>
                </strong>
                <em>
                    We keep your information for as long as necessary to <bdt className="block-component"></bdt>
                    fulfill<bdt className="statement-end-if-in-editor"></bdt>
                    the purposes outlined in this privacy notice unless otherwise required by law.
                </em>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).<bdt className="block-component"></bdt>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                When we have no ongoing legitimate business need to process your personal information, we will either delete or <bdt className="block-component"></bdt>
                anonymize<bdt className="statement-end-if-in-editor"></bdt>
                such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                <span classNameName="color: rgb(89, 89, 89);">
                    <bdt className="block-component"></bdt>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div id="infosafe" classNameName="line-height: 1.5;">
    <span classNameName="color: rgb(127, 127, 127);">
        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span id="control" classNameName="color: rgb(0, 0, 0);">
                        <strong>
                            <span className="heading_1">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
                        </strong>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <strong>
                    <em>In Short:�</em>
                </strong>
                <em>
                    We aim to protect your personal information through a system of <bdt className="block-component"></bdt>
                    organizational<bdt className="statement-end-if-in-editor"></bdt>
                    and technical security measures.
                </em>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                We have implemented appropriate and reasonable technical and <bdt className="block-component"></bdt>
                organizational<bdt className="statement-end-if-in-editor"></bdt>
                security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other <bdt className="block-component"></bdt>
                unauthorized<bdt className="statement-end-if-in-editor"></bdt>
                third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
                <span classNameName="color: rgb(89, 89, 89);">
                    <bdt className="statement-end-if-in-editor"></bdt>
                </span>
                <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                    <span className="body_text">
                        <bdt className="block-component"></bdt>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div id="infominors" classNameName="line-height: 1.5;">
    <span classNameName="color: rgb(127, 127, 127);">
        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span id="control" classNameName="color: rgb(0, 0, 0);">
                        <strong>
                            <span className="heading_1">8. DO WE COLLECT INFORMATION FROM MINORS?</span>
                        </strong>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <strong>
                    <em>In Short:</em>
                </strong>
                <em>
                    �We do not knowingly collect data from or market to <bdt className="block-component"></bdt>
                    children under 18 years of age<bdt className="else-block"></bdt>
                    .
                </em>
                <bdt className="block-component"></bdt>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent�s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at 
                <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                    <span className="body_text">
                        <bdt className="block-component"></bdt>
                        <bdt className="question">Info@worktechnologies.co.in</bdt>
                        <bdt className="else-block"></bdt>
                    </span>
                </span>
                .
            </span>
            <span className="body_text">
                <bdt className="else-block">
                    <bdt className="block-component"></bdt>
                </bdt>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div id="privacyrights" classNameName="line-height: 1.5;">
    <span classNameName="color: rgb(127, 127, 127);">
        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span id="control" classNameName="color: rgb(0, 0, 0);">
                        <strong>
                            <span className="heading_1">9. WHAT ARE YOUR PRIVACY RIGHTS?</span>
                        </strong>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <strong>
                    <em>In Short:</em>
                </strong>
                <em>
                    �
                    <span classNameName="color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px;">
                            <span className="body_text">
                                <em>
                                    <bdt className="block-component"></bdt>
                                </em>
                            </span>
                        </span>
                        �
                    </span>
                    You may review, change, or terminate your account at any time.
                </em>
                <span classNameName="color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px;">
                        <bdt className="block-component">
                            <bdt className="block-component"></bdt>
                        </bdt>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div id="withdrawconsent" classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <strong>
                    <u>Withdrawing your consent:</u>
                </strong>
                If we are relying on your consent to process your personal information,<bdt className="block-component"></bdt>
                which may be express and/or implied consent depending on the applicable law,<bdt className="statement-end-if-in-editor"></bdt>
                you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section <bdt className="block-component"></bdt>
                "<bdt className="statement-end-if-in-editor"></bdt>
            </span>
        </span>
    </span>
    <a className="link" href="#contact">
        <span classNameName="font-size: 15px; color: rgb(0, 58, 250);">
            <span classNameName="font-size: 15px; color: rgb(0, 58, 250);">
                <span className="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
            </span>
        </span>
    </a>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <bdt className="block-component"></bdt>
                "<bdt className="statement-end-if-in-editor"></bdt>
                below<bdt className="block-component"></bdt>
                .
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,<bdt className="block-component"></bdt>
            when applicable law allows,<bdt className="statement-end-if-in-editor"></bdt>
            will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.<bdt className="block-component"></bdt>
        </span>
    </span>
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px;">
                    <span classNameName="color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px;">
                            <span classNameName="color: rgb(89, 89, 89);">
                                <span className="body_text">
                                    <span classNameName="font-size: 15px;">
                                        <span classNameName="color: rgb(89, 89, 89);">
                                            <bdt className="block-component"></bdt>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <strong>
                    <u>Cookies and similar technologies:</u>
                </strong>
                Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. <bdt className="block-component"></bdt>
                You may also 
                <span classNameName="color: rgb(0, 58, 250);">
                    <span className="body_text">
                        <a className="link" href="http://www.aboutads.info/choices/" rel="noopener noreferrer" target="_blank">
                            <span classNameName="color: rgb(0, 58, 250); font-size: 15px;">opt out of interest-based advertising by advertisers</span>
                        </a>
                    </span>
                </span>
                on our Services. 
                <span classNameName="font-size: 15px;">
                    <span classNameName="color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <bdt className="block-component"></bdt>
                        </span>
                    </span>
                </span>
                For further information, please see our Cookie Notice: 
                <span classNameName="font-size: 15px;">
                    <span classNameName="color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <span classNameName="color: rgb(0, 58, 250);">
                                <bdt className="question">
                                    <a href="http://www.worktechnologies.co.in/cookies" target="_blank" className="link">http://www.worktechnologies.co.in/cookies</a>
                                </bdt>
                            </span>
                            .<bdt className="block-component"></bdt>
                            <span classNameName="font-size: 15px;">
                                <span classNameName="color: rgb(89, 89, 89);">
                                    <span classNameName="font-size: 15px;">
                                        <span classNameName="color: rgb(89, 89, 89);">
                                            <span classNameName="font-size: 15px;">
                                                <span classNameName="color: rgb(89, 89, 89);">
                                                    <bdt className="statement-end-if-in-editor"></bdt>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
    <bdt className="block-component">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span className="body_text">
        <span classNameName="font-size: 15px;">
            If you have questions or comments about your privacy rights, you may email us at <bdt className="question">info@worktechnologies.co.in</bdt>
            .
        </span>
    </span>
    <bdt className="statement-end-if-in-editor">
        <span classNameName="font-size: 15px;">
            <span className="body_text"></span>
        </span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div id="DNT" classNameName="line-height: 1.5;">
    <span classNameName="color: rgb(127, 127, 127);">
        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span id="control" classNameName="color: rgb(0, 0, 0);">
                        <strong>
                            <span className="heading_1">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span>
                        </strong>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (<bdt className="block-component"></bdt>
                "DNT"<bdt className="statement-end-if-in-editor"></bdt>
                ) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for <bdt className="block-component"></bdt>
                recognizing<bdt className="statement-end-if-in-editor"></bdt>
                and implementing DNT signals has been <bdt className="block-component"></bdt>
                finalized<bdt className="statement-end-if-in-editor"></bdt>
                . As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.<bdt className="block-component"></bdt>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div id="uslaws" classNameName="line-height: 1.5;">
    <span classNameName="color: rgb(127, 127, 127);">
        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span id="control" classNameName="color: rgb(0, 0, 0);">
                        <strong>
                            <span className="heading_1">11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span>
                        </strong>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <strong>
                    <em>In Short:�</em>
                </strong>
                <em>
                    If you are a resident of <bdt className="forloop-component"></bdt>
                    <bdt className="block-component"></bdt>
                    <bdt className="forloop-component"></bdt>
                    California<bdt className="forloop-component"></bdt>
                    <bdt className="forloop-component"></bdt>
                    <bdt className="forloop-component"></bdt>
                    <bdt className="forloop-component"></bdt>
                    <bdt className="block-component"></bdt>
                    <bdt className="forloop-component"></bdt>
                    , you are granted specific rights regarding access to your personal information.
                </em>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <strong>What categories of personal information do we collect?</strong>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">We have collected the following categories of personal information in the past twelve (12) months:</span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<table classNameName="width: 100%;">
    <tbody>
        <tr>
            <td classNameName="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <strong>Category</strong>
                        </span>
                    </span>
                </span>
            </td>
            <td classNameName="width: 51.4385%; border-top: 1px solid black; border-right: 1px solid black;">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <strong>Examples</strong>
                        </span>
                    </span>
                </span>
            </td>
            <td classNameName="width: 14.9084%; border-right: 1px solid black; border-top: 1px solid black; text-align: center;">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <strong>Collected</strong>
                        </span>
                    </span>
                </span>
            </td>
        </tr>
        <tr>
            <td classNameName="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">A. Identifiers</span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 51.4385%; border-top: 1px solid black; border-right: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 14.9084%; text-align: center; vertical-align: middle; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <bdt className="block-component">
                                    <bdt className="block-component"></bdt>
                                </bdt>
                                NO
                                <bdt className="statement-end-if-in-editor">
                                    <bdt className="block-component"></bdt>
                                </bdt>
                            </span>
                        </span>
                    </span>
                </div>
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
            </td>
        </tr>
    </tbody>
</table>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component"></bdt>
</div>
<table classNameName="width: 100%;">
    <tbody>
        <tr>
            <td classNameName="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">B. Personal information as defined in the California Customer Records statute</span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">Name, contact information, education, employment, employment history, and financial information</span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <bdt className="forloop-component">
                                    <bdt className="block-component">
                                        <bdt className="block-component">
                                            NO
                                            <bdt className="statement-end-if-in-editor">
                                                <bdt className="block-component"></bdt>
                                            </bdt>
                                        </bdt>
                                    </bdt>
                                </bdt>
                            </span>
                        </span>
                    </span>
                </div>
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
            </td>
        </tr>
    </tbody>
</table>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component"></bdt>
</div>
<table classNameName="width: 100%;">
    <tbody>
        <tr>
            <td classNameName="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <bdt className="block-component"></bdt>
                                C<bdt className="else-block"></bdt>
                                . Protected classNameification characteristics under state or federal law
                            </span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">Gender and date of birth</span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
                <div className="body_text" classNameName="line-height: 1.5;">
                    <bdt className="forloop-component">
                        <span className="body_text">
                            <bdt className="block-component"></bdt>
                            <bdt className="block-component"></bdt>
                            NO<bdt className="statement-end-if-in-editor"></bdt>
                            <bdt className="block-component"></bdt>
                        </span>
                    </bdt>
                </div>
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
            </td>
        </tr>
        <tr>
            <td classNameName="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <bdt className="block-component"></bdt>
                                D<bdt className="else-block"></bdt>
                                . Commercial information
                            </span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">Transaction information, purchase history, financial details, and payment information</span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
                <div className="body_text" classNameName="line-height: 1.5;">
                    <bdt className="forloop-component">
                        <span className="body_text">
                            <bdt className="block-component"></bdt>
                            <bdt className="block-component"></bdt>
                            NO
                            <bdt className="statement-end-if-in-editor"></bdt>
                                <bdt className="block-component"></bdt>
                        </span>
                    </bdt>
                </div>
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
            </td>
        </tr>
        <tr>
            <td classNameName="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <bdt className="block-component"></bdt>
                                E<bdt className="else-block"></bdt>
                                . Biometric information
                            </span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">Fingerprints and voiceprints</span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
                <div className="body_text" classNameName="line-height: 1.5;">
                    <bdt className="forloop-component">
                        <span className="body_text">
                            <bdt className="block-component">
                                <bdt className="block-component">NO</bdt>
                                <bdt className="statement-end-if-in-editor"></bdt>
                                <bdt className="block-component"></bdt>
                            </bdt>
                        </span>
                    </bdt>
                </div>
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
            </td>
        </tr>
        <tr>
            <td classNameName="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <bdt className="block-component"></bdt>
                                F<bdt className="else-block"></bdt>
                                . Internet or other similar network activity
                            </span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                Browsing history, search history, online <bdt className="block-component"></bdt>
                                behavior<bdt className="statement-end-if-in-editor"></bdt>
                                , interest data, and interactions with our and other websites, applications, systems, and advertisements
                            </span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
                <div className="body_text" classNameName="line-height: 1.5;">
                    <bdt className="forloop-component">
                        <span className="body_text">
                            <bdt className="block-component"></bdt>
                            <bdt className="block-component"></bdt>
                            NO<bdt className="statement-end-if-in-editor"></bdt>
                            <bdt className="block-component"></bdt>
                        </span>
                    </bdt>
                </div>
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
            </td>
        </tr>
        <tr>
            <td classNameName="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <bdt className="block-component"></bdt>
                                G<bdt className="else-block"></bdt>
                                . Geolocation data
                            </span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">Device location</span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
                <div className="body_text" classNameName="line-height: 1.5;">
                    <bdt className="forloop-component">
                        <span className="body_text">
                            <bdt className="block-component"></bdt>
                            <bdt className="block-component"></bdt>
                            YES<bdt className="else-block"></bdt>
                            <bdt className="block-component"></bdt>
                        </span>
                    </bdt>
                </div>
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
            </td>
        </tr>
        <tr>
            <td classNameName="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <bdt className="block-component"></bdt>
                                H<bdt className="else-block"></bdt>
                                . Audio, electronic, visual, thermal, olfactory, or similar information
                            </span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">Images and audio, video or call recordings created in connection with our business activities</span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
                <div className="body_text" classNameName="line-height: 1.5;">
                    <bdt className="forloop-component">
                        <span className="body_text">
                            <bdt className="block-component"></bdt>
                            <bdt className="block-component"></bdt>
                            NO<bdt className="statement-end-if-in-editor"></bdt>
                            <bdt className="block-component"></bdt>
                        </span>
                    </bdt>
                </div>
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
            </td>
        </tr>
        <tr>
            <td classNameName="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <bdt className="block-component"></bdt>
                                I<bdt className="else-block"></bdt>
                                . Professional or employment-related information
                            </span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
                <div className="body_text" classNameName="line-height: 1.5;">
                    <bdt className="forloop-component">
                        <span className="body_text">
                            <bdt className="block-component"></bdt>
                            <bdt className="block-component"></bdt>
                            YES<bdt className="else-block"></bdt>
                            <bdt className="block-component"></bdt>
                        </span>
                    </bdt>
                </div>
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
            </td>
        </tr>
        <tr>
            <td classNameName="border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black; width: 33.8274%;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <bdt className="block-component"></bdt>
                                J<bdt className="else-block"></bdt>
                                . Education Information
                            </span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="border-right: 1px solid black; border-top: 1px solid black; width: 51.4385%;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">Student records and directory information</span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="text-align: center; border-right: 1px solid black; border-top: 1px solid black; width: 14.9084%;">
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
                <div className="body_text" classNameName="line-height: 1.5;">
                    <bdt className="forloop-component">
                        <span className="body_text">
                            <bdt className="block-component"></bdt>
                            <bdt className="block-component"></bdt>
                            NO<bdt className="statement-end-if-in-editor"></bdt>
                            <bdt className="block-component"></bdt>
                        </span>
                    </bdt>
                </div>
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
            </td>
        </tr>
        <tr>
            <td classNameName="border-width: 1px; border-color: black; border-classNameName: solid; width: 33.8274%;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <bdt className="block-component"></bdt>
                                K<bdt className="else-block"></bdt>
                                . Inferences drawn from collected personal information
                            </span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="border-bottom: 1px solid black; border-top: 1px solid black; border-right: 1px solid black; width: 51.4385%;">
                <div classNameName="line-height: 1.5;">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual�s preferences and characteristics</span>
                        </span>
                    </span>
                </div>
            </td>
            <td classNameName="text-align: center; border-right: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black; width: 14.9084%;">
                <div classNameName="line-height: 1.5;">
                    <br/>
                </div>
                <div className="body_text" classNameName="line-height: 1.5;">
                    <span className="body_text">
                        <bdt className="block-component"></bdt>
                        NO
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                <span className="body_text">
                                    <span classNameName="color: rgb(89, 89, 89);">
                                        <span className="body_text">
                                            <bdt className="statement-end-if-in-editor"></bdt>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
</td></tr>
<tr>
    <td classNameName="border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black; line-height: 1.5;">
        <span className="body_text">
            <bdt className="block-component"></bdt>
            L<bdt className="else-block"></bdt>
            . Sensitive personal Information
        </span>
    </td>
    <td classNameName="border-right: 1px solid black; border-bottom: 1px solid black; line-height: 1.5;">
        <bdt className="block-component">
            <span className="body_text"></span>
        </bdt>
    </td>
    <td classNameName="border-right: 1px solid black; border-bottom: 1px solid black;">
        <div data-empty="true" classNameName="text-align: center;">
            <br/>
        </div>
        <div className="body_text" data-empty="true" classNameName="text-align: center; line-height: 1.5;">
            <bdt className="block-component">
                <span className="body_text"></span>
            </bdt>
            NO
        <bdt className="statement-end-if-in-editor">
            <span className="body_text"></span>
        </bdt>
</div>
<div data-empty="true" classNameName="text-align: center;">
    <br/>
</div>
</td></tr></tbody></table>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span className="body_text"></span>
    </bdt>
    <span className="body_text">
        We will use and retain the collected personal information as needed to provide the Services or for:<bdt className="block-component"></bdt>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span className="body_text">
        <bdt className="block-component"></bdt>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span className="body_text">
            Category B - <bdt className="question">1Day</bdt>
            <bdt className="statement-end-if-in-editor"></bdt>
        </span>
    </li>
</ul>
<div classNameName="line-height: 1.5;">
    <span className="body_text">
        <bdt className="block-component"></bdt>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span className="body_text">
        <bdt className="block-component"></bdt>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span className="body_text">
        <bdt className="block-component"></bdt>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span className="body_text">
        <bdt className="block-component"></bdt>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span className="body_text">
        <bdt className="block-component"></bdt>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span className="body_text">
            Category <bdt className="block-component"></bdt>
            G<bdt className="else-block"></bdt>
            - <bdt className="question">1Day</bdt>
            <bdt className="statement-end-if-in-editor"></bdt>
        </span>
    </li>
</ul>
<div classNameName="line-height: 1.5;">
    <span className="body_text">
        <bdt className="block-component"></bdt>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span className="body_text">
        <bdt className="block-component"></bdt>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span className="body_text">
            Category <bdt className="block-component"></bdt>
            I<bdt className="else-block"></bdt>
            - <bdt className="question">6 months</bdt>
            <bdt className="statement-end-if-in-editor"></bdt>
        </span>
    </li>
</ul>
<div classNameName="line-height: 1.5;">
    <span className="body_text">
        <bdt className="block-component"></bdt>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span className="body_text">
        <bdt className="block-component"></bdt>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span className="body_text">
        <bdt className="block-component"></bdt>
    </span>
    <bdt className="statement-end-if-in-editor">
        <span className="body_text"></span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <bdt className="block-component"></bdt>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                <span className="body_text">
                                    <bdt className="block-component"></bdt>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span className="body_text">Receiving help through our customer support channels;</span>
                <span className="body_text">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                    <span className="body_text">
                                        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                            <span className="body_text">
                                                <span classNameName="font-size: 15px;">
                                                    <span classNameName="color: rgb(89, 89, 89);">
                                                        <span className="body_text">
                                                            <bdt className="statement-end-if-in-editor"></bdt>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </li>
</ul>
<div>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                <span className="body_text">
                                    <bdt className="block-component"></bdt>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span className="body_text">Participation in customer surveys or contests; and</span>
                <span className="body_text">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                    <span className="body_text">
                                        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                            <span className="body_text">
                                                <span classNameName="font-size: 15px;">
                                                    <span classNameName="color: rgb(89, 89, 89);">
                                                        <span className="body_text">
                                                            <bdt className="statement-end-if-in-editor"></bdt>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </li>
</ul>
<div>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                <span className="body_text">
                                    <bdt className="block-component"></bdt>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span className="body_text">Facilitation in the delivery of our Services and to respond to your inquiries.</span>
                <span className="body_text">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                    <span className="body_text">
                                        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                            <span className="body_text">
                                                <span classNameName="font-size: 15px;">
                                                    <span classNameName="color: rgb(89, 89, 89);">
                                                        <span className="body_text">
                                                            <bdt className="statement-end-if-in-editor"></bdt>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </li>
</ul>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <strong>How do we use and share your personal information?</strong>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span className="body_text" classNameName="font-size: 15px;">
        Learn about how we use your personal information in the section, <bdt className="block-component"></bdt>
        "<bdt className="statement-end-if-in-editor"></bdt>
    </span>
    <a className="link" href="#infouse">
        <span classNameName="color: rgb(0, 58, 250); font-size: 15px;">HOW DO WE PROCESS YOUR INFORMATION?</span>
    </a>
    <span className="body_text" classNameName="font-size: 15px;">
        <bdt className="block-component"></bdt>
        "
    </span>
    <bdt className="statement-end-if-in-editor">
        <span className="body_text" classNameName="font-size: 15px;"></span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                    <span className="body_text">
                        <bdt className="block-component"></bdt>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>

<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <strong>Will your information be shared with anyone else?</strong>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, <bdt className="block-component"></bdt>
                "<bdt className="statement-end-if-in-editor"></bdt>
            </span>
        </span>
    </span>
    <a className="link" href="#whoshare">
        <span classNameName="font-size: 15px; color: rgb(0, 58, 250);">
            <span classNameName="font-size: 15px; color: rgb(0, 58, 250);">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span>
        </span>
    </a>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <bdt className="block-component"></bdt>
                "<bdt className="statement-end-if-in-editor"></bdt>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be <bdt className="block-component"></bdt>
                "selling"<bdt className="statement-end-if-in-editor"></bdt>
                of your personal information.
                <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                    <span className="body_text">
                        <bdt className="block-component"></bdt>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We<span classNameName="color: rgb(89, 89, 89);">�</span>
                will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
                <span classNameName="color: rgb(89, 89, 89);">
                    <span className="body_text">
                        <span classNameName="color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <span classNameName="color: rgb(89, 89, 89);">
                                    <span className="body_text">
                                        <bdt className="statement-end-if-in-editor"></bdt>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <bdt className="block-component"></bdt>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
    <span className="body_text">
        <span classNameName="color: rgb(0, 0, 0);">
            <span className="body_text">
                <bdt className="block-component"></bdt>
            </span>
        </span>
    </span>
    <bdt className="block-component"></bdt>

<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <strong>
        <span className="heading_2" classNameName="font-size: 15px;">California Residents</span>
    </strong>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span className="body_text" classNameName="font-size: 15px;">
        California Civil Code Section 1798.83, also known as the <bdt className="block-component"></bdt>
        "Shine The Light"<bdt className="statement-end-if-in-editor"></bdt>
        law permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <br/>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span className="body_text" classNameName="font-size: 15px;">
        If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g.<bdt className="block-component"></bdt>
        ,<bdt className="statement-end-if-in-editor"></bdt>
        backups, etc.).
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <br/>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <strong>
                <u>CCPA Privacy Notice</u>
            </strong>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <br/>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">This section applies only to California residents. Under the California Consumer Privacy Act (CCPA), you have the rights listed below.</span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <br/>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            The California Code of Regulations defines a <bdt className="block-component"></bdt>
            "residents"<bdt className="statement-end-if-in-editor"></bdt>
            as:
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <br/>
        </span>
    </span>
</div>
<div classNameName="margin-left: 20px; line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">(1) every individual who is in the State of California for other than a temporary or transitory purpose and</span>
    </span>
</div>
<div classNameName="margin-left: 20px; line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <br/>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            All other individuals are defined as <bdt className="block-component"></bdt>
            "non-residents."<bdt className="statement-end-if-in-editor"></bdt>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <br/>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            If this definition of <bdt className="block-component"></bdt>
            "resident"<bdt className="statement-end-if-in-editor"></bdt>
            applies to you, we must adhere to certain rights and obligations regarding your personal information.
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <strong>Your rights with respect to your personal data</strong>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <u>Right to request deletion of the data � Request to delete</u>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.</span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <u>Right to be informed � Request to know</u>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                Depending on the circumstances, you have a right to know:
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                    <span className="body_text">
                                        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                            <span className="body_text">
                                                <bdt className="block-component"></bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span className="body_text">
                    whether we collect and use your personal information;
                    <span classNameName="color: rgb(0, 0, 0);">
                        <span classNameName="font-size: 15px;">
                            <span className="body_text">
                                <span classNameName="color: rgb(0, 0, 0);">
                                    <span classNameName="font-size: 15px;">
                                        <span className="body_text">
                                            <bdt className="statement-end-if-in-editor"></bdt>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </li>
</ul>
<div>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                    <span className="body_text">
                                        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                            <span className="body_text">
                                                <bdt className="block-component"></bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span className="body_text">
                    the categories of personal information that we collect;
                    <span classNameName="color: rgb(0, 0, 0);">
                        <span classNameName="font-size: 15px;">
                            <span className="body_text">
                                <span classNameName="color: rgb(0, 0, 0);">
                                    <span classNameName="font-size: 15px;">
                                        <span className="body_text">
                                            <bdt className="statement-end-if-in-editor"></bdt>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </li>
</ul>
<div>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                    <span className="body_text">
                                        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                            <span className="body_text">
                                                <bdt className="block-component"></bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span className="body_text">
                    the purposes for which the collected personal information is used;
                    <span classNameName="color: rgb(0, 0, 0);">
                        <span classNameName="font-size: 15px;">
                            <span className="body_text">
                                <span classNameName="color: rgb(0, 0, 0);">
                                    <span classNameName="font-size: 15px;">
                                        <span className="body_text">
                                            <bdt className="statement-end-if-in-editor"></bdt>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </li>
</ul>
<div>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                    <span className="body_text">
                                        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                            <span className="body_text">
                                                <bdt className="block-component"></bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span className="body_text">
                    whether we sell or share personal information to third parties;
                    <span classNameName="color: rgb(0, 0, 0);">
                        <span classNameName="font-size: 15px;">
                            <span className="body_text">
                                <span classNameName="color: rgb(0, 0, 0);">
                                    <span classNameName="font-size: 15px;">
                                        <span className="body_text">
                                            <bdt className="statement-end-if-in-editor"></bdt>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </li>
</ul>
<div>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                    <span className="body_text">
                                        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                            <span className="body_text">
                                                <bdt className="block-component"></bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span className="body_text">
                    the categories of personal information that we sold, shared, or disclosed for a business purpose;
                    <span classNameName="color: rgb(0, 0, 0);">
                        <span classNameName="font-size: 15px;">
                            <span className="body_text">
                                <span classNameName="color: rgb(0, 0, 0);">
                                    <span classNameName="font-size: 15px;">
                                        <span className="body_text">
                                            <bdt className="statement-end-if-in-editor"></bdt>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </li>
</ul>
<div>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                    <span className="body_text">
                                        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                            <span className="body_text">
                                                <bdt className="block-component"></bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span className="body_text">
                    the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;
                    <span classNameName="color: rgb(0, 0, 0);">
                        <span classNameName="font-size: 15px;">
                            <span className="body_text">
                                <span classNameName="color: rgb(0, 0, 0);">
                                    <span classNameName="font-size: 15px;">
                                        <span className="body_text">
                                            <bdt className="statement-end-if-in-editor"></bdt>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </li>
</ul>
<div>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                    <span className="body_text">
                                        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                            <span className="body_text">
                                                <bdt className="block-component"></bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span className="body_text">
                    the business or commercial purpose for collecting, selling, or sharing personal information; and
                    <span classNameName="color: rgb(0, 0, 0);">
                        <span classNameName="font-size: 15px;">
                            <span className="body_text">
                                <span classNameName="color: rgb(0, 0, 0);">
                                    <span classNameName="font-size: 15px;">
                                        <span className="body_text">
                                            <bdt className="statement-end-if-in-editor"></bdt>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </li>
</ul>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span className="body_text"></span>
    </bdt>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span className="body_text">
            the specific pieces of personal information we collected about you.<bdt className="statement-end-if-in-editor"></bdt>
        </span>
    </li>
</ul>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <u>Right to Non-Discrimination for the Exercise of a Consumer�s Privacy Rights</u>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">We will not discriminate against you if you exercise your privacy rights.</span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <u>
            <span className="body_text">Right to Limit Use and Disclosure of Sensitive Personal Information</span>
        </u>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span className="body_text"></span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span className="body_text">We do not process consumer's sensitive personal information.</span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="statement-end-if-in-editor">
        <span className="body_text"></span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <u>Verification process</u>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g.<bdt className="block-component"></bdt>
                ,<bdt className="statement-end-if-in-editor"></bdt>
                phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.</span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <u>Other privacy rights</u>
            </span>
        </span>
    </span>
</div>
<div>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                    <span className="body_text">
                                        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                            <span className="body_text">
                                                <bdt className="block-component"></bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span className="body_text">
                    You may object to the processing of your personal information.
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <span classNameName="color: rgb(0, 0, 0);">
                                    <span classNameName="font-size: 15px;">
                                        <span className="body_text">
                                            <span classNameName="color: rgb(0, 0, 0);">
                                                <span classNameName="font-size: 15px;">
                                                    <span className="body_text">
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </li>
</ul>
<div>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                    <span className="body_text">
                                        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                            <span className="body_text">
                                                <bdt className="block-component"></bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span className="body_text">
                    You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <span classNameName="color: rgb(0, 0, 0);">
                                    <span classNameName="font-size: 15px;">
                                        <span className="body_text">
                                            <span classNameName="color: rgb(0, 0, 0);">
                                                <span classNameName="font-size: 15px;">
                                                    <span className="body_text">
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </li>
</ul>
<div>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                    <span className="body_text">
                                        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                            <span className="body_text">
                                                <bdt className="block-component"></bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span className="body_text">
                    You can designate an <bdt className="block-component"></bdt>
                    authorized<bdt className="statement-end-if-in-editor"></bdt>
                    agent to make a request under the CCPA on your behalf. We may deny a request from an <bdt className="block-component"></bdt>
                    authorized<bdt className="statement-end-if-in-editor"></bdt>
                    agent that does not submit proof that they have been validly <bdt className="block-component"></bdt>
                    authorized<bdt className="statement-end-if-in-editor"></bdt>
                    to act on your behalf in accordance with the CCPA.
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <span classNameName="color: rgb(0, 0, 0);">
                                    <span classNameName="font-size: 15px;">
                                        <span className="body_text">
                                            <span classNameName="color: rgb(0, 0, 0);">
                                                <span classNameName="font-size: 15px;">
                                                    <span className="body_text">
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </li>
</ul>
<div>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">
                            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                    <span className="body_text">
                                        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                            <span className="body_text">
                                                <bdt className="block-component"></bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<ul>
    <li classNameName="line-height: 1.5;">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span className="body_text">
                    You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <span classNameName="color: rgb(0, 0, 0);">
                                    <span classNameName="font-size: 15px;">
                                        <span className="body_text">
                                            <span classNameName="color: rgb(0, 0, 0);">
                                                <span classNameName="font-size: 15px;">
                                                    <span className="body_text">
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </li>
</ul>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;"></span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                To exercise these rights, you can contact us <bdt className="block-component"></bdt>
                by submitting a�
            </span>
        </span>
    </span>
    <a className="link" href="https://app.termly.io/notify/0e29da7e-29d5-4ed6-b483-d4e0dbd4d7a1" rel="noopener noreferrer" target="_blank">
        <span classNameName="font-size: 15px; color: rgb(0, 58, 250);">
            <span classNameName="font-size: 15px; color: rgb(0, 58, 250);">data subject access request</span>
        </span>
    </a>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                , <bdt className="block-component"></bdt>
            </span>
            <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                <span className="body_text">
                    <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                        <span className="body_text">
                            <bdt className="block-component"></bdt>
                            by email at <bdt className="question">info@worktechnologies.co.in</bdt>
                            , <bdt className="statement-end-if-in-editor"></bdt>
                            <bdt className="block-component"></bdt>
                        </span>
                        <span className="body_text">
                            <bdt className="block-component"></bdt>
                            <bdt className="block-component">
                                <span className="body_text"></span>
                            </bdt>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
    <span className="body_text">or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.</span>
    <span className="color: rgb(89, 89, 89); font-size: 15px;">
        <span className="body_text">
            <bdt className="block-component">
                <bdt className="block-component"></bdt>
            </bdt>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component">
        <span classNameName="font-size: 15px;"></span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <bdt className="block-component"></bdt>
    <bdt className="block-component">
        <span classNameName="font-size: 15px;"></span>
    </bdt>
    <bdt className="block-component">
        <span classNameName="font-size: 15px;"></span>
    </bdt>
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                <span className="body_text">
                    <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                        <span className="body_text">
                            <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                <span className="body_text">
                                    <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                        <span className="body_text">
                                            <bdt className="statement-end-if-in-editor">
                                                <span className="body_text">
                                                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                                        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                                                            <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                                                <span className="body_text">
                                                                    <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                                                        <span className="body_text">
                                                                            <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                                                                <span className="body_text">
                                                                                    <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                                                                                        <span className="body_text">
                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                                <bdt className="statement-end-if-in-editor"></bdt>
                                                                                            </bdt>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </bdt>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
    <bdt className="block-component">
        <span classNameName="font-size: 15px;"></span>
    </bdt>

<bdt className="block-component">
    <span classNameName="font-size: 15px;"></span>
</bdt>
<span classNameName="font-size: 15px;">
    <bdt className="block-component"></bdt>
</span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <br/>
    </span>
</div>
<div id="clausea" classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <strong>
            <span className="heading_1">12.�</span>
        </strong>
        <bdt className="question">
            <strong>
                <span className="heading_1">DATANOTSTOREDWITHUS</span>
            </strong>
        </bdt>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <br/>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <bdt className="question">
            <span className="body_text">datanotstoredwithus</span>
        </bdt>
        <bdt className="statement-end-if-in-editor"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="block-component"></bdt>
    </span>
    <bdt className="statement-end-if-in-editor">
        <span classNameName="font-size: 15px;"></span>
    </bdt>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div id="policyupdates" classNameName="line-height: 1.5;">
    <span classNameName="color: rgb(127, 127, 127);">
        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span id="control" classNameName="color: rgb(0, 0, 0);">
                        <strong>
                            <span className="heading_1">13. DO WE MAKE UPDATES TO THIS NOTICE?</span>
                        </strong>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <em>
                    <strong>In Short:�</strong>
                    Yes, we will update this notice as necessary to stay compliant with relevant laws.
                </em>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                We may update this privacy notice from time to time. The updated version will be indicated by an updated <bdt className="block-component"></bdt>
                "Revised"<bdt className="statement-end-if-in-editor"></bdt>
                date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div id="contact" classNameName="line-height: 1.5;">
    <span classNameName="color: rgb(127, 127, 127);">
        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span id="control" classNameName="color: rgb(0, 0, 0);">
                        <strong>
                            <span className="heading_1">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
                        </strong>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                If you have questions or comments about this notice, you may 
                <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                    <span className="body_text">
                        <bdt className="block-component">
                            <bdt className="block-component"></bdt>
                        </bdt>
                        email us at <bdt className="question">Info@worktechnologies.co.in or�</bdt>
                        <bdt className="statement-end-if-in-editor">
                            <bdt className="block-component"></bdt>
                        </bdt>
                    </span>
                </span>
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                        <span className="body_text">contact us by post at:</span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <span classNameName="font-size: 15px;">
                    <span classNameName="color: rgb(89, 89, 89);">
                        <span classNameName="color: rgb(89, 89, 89);">
                            <span className="body_text">
                                <bdt className="question">worktechnologies</bdt>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
            <span className="body_text">
                <span classNameName="color: rgb(89, 89, 89);">
                    <span className="body_text">
                        <bdt className="block-component"></bdt>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <bdt className="question">Plot No 174/1G . Floor </bdt>
            <span classNameName="color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px;">
                    <bdt className="block-component"></bdt>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span className="body_text" classNameName="font-size: 15px;">
        <bdt className="question">
            Street No.12 Bahrampur GZB - 201009
            <span className="body_text">
                <span classNameName="color: rgb(89, 89, 89);">
                    <span classNameName="font-size: 15px;">
                        <bdt className="statement-end-if-in-editor"></bdt>
                    </span>
                </span>
            </span>
        </bdt>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <bdt className="question">GZB</bdt>
            <span classNameName="color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px;">
                    <bdt className="block-component"></bdt>
                    , <bdt className="question">Uttarpradesh</bdt>
                    <bdt className="statement-end-if-in-editor"></bdt>
                    <bdt className="block-component"></bdt>
                    <bdt className="question">201009</bdt>
                    <bdt className="statement-end-if-in-editor"></bdt>
                    <bdt className="block-component"></bdt>
                    <bdt className="block-component"></bdt>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px;">
        <span className="body_text">
            <span classNameName="font-size: 15px;">
                <span className="body_text">
                    <span classNameName="color: rgb(89, 89, 89);">
                        <bdt className="block-component"></bdt>
                    </span>
                </span>
            </span>
            <bdt className="question">India</bdt>
            <span classNameName="font-size: 15px;">
                <span className="body_text">
                    <span classNameName="color: rgb(89, 89, 89);">
                        <bdt className="statement-end-if-in-editor">
                            <span classNameName="font-size: 15px;">
                                <span className="body_text">
                                    <span classNameName="color: rgb(89, 89, 89);">
                                        <bdt className="statement-end-if-in-editor">
                                            <span classNameName="font-size: 15px;">
                                                <span className="body_text">
                                                    <span classNameName="color: rgb(89, 89, 89);">
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                    </span>
                                                </span>
                                            </span>
                                        </bdt>
                                        <bdt className="statement-end-if-in-editor"></bdt>
                                    </span>
                                </span>
                            </span>
                            <span className="body_text">
                                <span classNameName="color: rgb(89, 89, 89);">
                                    <span classNameName="font-size: 15px;">
                                        <bdt className="statement-end-if-in-editor"></bdt>
                                    </span>
                                </span>
                            </span>
                        </bdt>
                    </span>
                </span>
            </span>
        </span>
        <span className="body_text">
            <span classNameName="font-size: 15px;">
                <span className="body_text">
                    <span classNameName="color: rgb(89, 89, 89);">
                        <bdt className="statement-end-if-in-editor">
                            <span classNameName="color: rgb(89, 89, 89);">
                                <span classNameName="font-size: 15px;">
                                    <span className="body_text">
                                        <bdt className="block-component">
                                            <bdt className="block-component"></bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </bdt>
                    </span>
                </span>
            </span>
            <bdt className="block-component">
                <span classNameName="font-size: 15px;"></span>
            </bdt>
            <span classNameName="font-size: 15px;">
                <span className="body_text">
                    <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
                        <span classNameName="font-size: 15px;">
                            <span className="body_text">
                                <bdt className="statement-end-if-in-editor">
                                    <bdt className="block-component"></bdt>
                                </bdt>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div id="request" classNameName="line-height: 1.5;">
    <span classNameName="color: rgb(127, 127, 127);">
        <span classNameName="color: rgb(89, 89, 89); font-size: 15px;">
            <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
                    <span id="control" classNameName="color: rgb(0, 0, 0);">
                        <strong>
                            <span className="heading_1">15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span>
                        </strong>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
<div classNameName="line-height: 1.5;">
    <br/>
</div>
<div classNameName="line-height: 1.5;">
    <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
        <span classNameName="font-size: 15px; color: rgb(89, 89, 89);">
            <span className="body_text">
                <bdt className="block-component"></bdt>
                Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. 
                <bdt className="else-block">
                    <bdt className="block-component"></bdt>
                    To request to review, update, or delete your personal information, please <bdt className="block-component"></bdt>
                    fill out and submit a�
                </bdt>
            </span>
            <span classNameName="color: rgb(0, 58, 250);">
                <span className="body_text">
                    <span classNameName="color: rgb(0, 58, 250); font-size: 15px;">
                        <a className="link" href="https://app.termly.io/notify/0e29da7e-29d5-4ed6-b483-d4e0dbd4d7a1" rel="noopener noreferrer" target="_blank">data subject access request</a>
                    </span>
                </span>
            </span>
            <bdt className="block-component">
                <span className="body_text"></span>
            </bdt>
        </span>
    </span>
    <span className="body_text">.</span>
</div>
<div classNameName="color: #595959;font-size: 14px;font-family: Arial;padding-top:16px;">
    This privacy policy was created using Termly's <a classNameName="color: rgb(48, 48, 241) !important;" href="https://termly.io/products/privacy-policy-generator/">Privacy Policy Generator</a>
    .
      
</div>
        </div>
        )
}