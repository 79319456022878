import React from 'react';


export default function Hotjob(props) {
    return(
        <div className=" p-8">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">Join Our Team at Work Technologies!</h1>
                <p className="text-lg text-gray-700 text-center mb-12">
                    Are you passionate about innovation and excellence? At Work Technologies!, we’re always looking for talented individuals to join our dynamic team. We offer a collaborative work environment, opportunities for growth, and a commitment to employee development.
                </p>

                <h2 className="text-3xl font-semibold text-gray-800 mb-6">Why Work With Us?</h2>
                <ul className="list-disc list-inside mb-12">
                <li className="text-lg text-gray-700">Innovative Projects: Work on exciting projects that make an impact.</li>
                <li className="text-lg text-gray-700">Career Growth: We invest in your professional development.</li>
                <li className="text-lg text-gray-700">Inclusive Culture: Be part of a diverse and supportive community.</li>
                <li className="text-lg text-gray-700">Competitive Benefits: Enjoy a comprehensive benefits package.</li>
                </ul>

                {/* <h2 className="text-3xl font-semibold text-gray-800 mb-6">Current Openings:</h2>
                <div className="space-y-8 mb-12">
                <div>
                    <h3 className="text-2xl font-semibold text-gray-800">[Job Title 1]</h3>
                    <p className="text-lg text-gray-700">Brief description and key responsibilities.</p>
                </div>
                <div>
                    <h3 className="text-2xl font-semibold text-gray-800">[Job Title 2]</h3>
                    <p className="text-lg text-gray-700">Brief description and key responsibilities.</p>
                </div>
                <div>
                    <h3 className="text-2xl font-semibold text-gray-800">[Job Title 3]</h3>
                    <p className="text-lg text-gray-700">Brief description and key responsibilities.</p>
                </div>
                </div> */}

                <h2 className="text-3xl font-semibold text-gray-800 mb-6">How to Apply:</h2>
                <p className="text-lg text-gray-700 mb-8">
                    Interested candidates, please send your resume and cover letter to <a href="mailto:[email address]" className="text-blue-500">sushantkant@gmail.com</a>. Join us and be a part of something great!
                </p>
            </div>
        </div>
    )
}